import { MdFoodBank } from "react-icons/md";
import { MdWork } from "react-icons/md";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function OtherServices() {
  const services = [
    {
      icon: <MdWork size={25} />,
      name: "Import and Export",
      description:
        "Welcome to albaca worldwide tradelinks your trusted partner in global trade solutions. With expertise in import and export services, we facilitate seamless transactions across borders. From sourcing quality products to ensuring smooth logistics, we are committed to meeting your international trade needs. Explore our comprehensive range of services and discover how we can optimize your supply chain and drive business success.",
      image:
        "https://plus.unsplash.com/premium_photo-1661932036915-4fd90bec6e8a?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
      icon: <MdFoodBank size={35} />,
      name: "Tasty Hours",
      description:
        "Indulge in flavor and quality with Tasty Hours, your premier destination for gourmet food products. From farm-fresh ingredients to exotic delicacies, we curate a diverse selection to tantalize your taste buds. Whether you're a food enthusiast, restaurant owner, or retailer, we're your one-stop shop for premium culinary delights. Explora our range of delicious offerings and elevate your culinary experience today.",
      image:
        "https://images.unsplash.com/photo-1599490659213-e2b9527bd087?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
  ];
  return (
    <div className="flex flex-col justify-center  items-center max-w-screen-lg mx-auto py-7 md:py-20 md:pt-5 ">
      <div className="text-center p-5  max-w-screen-md">
        <h2 className="font-bold text-3xl">Other Services</h2>
        <p className="mt-2 md:mt-4 md:text-lg">
          In addition to our consultancy service we also operate Albaca World
          Wide Tradelinks and Tasty Hours.
        </p>
      </div>

      <main className="grid grid-cols-1 md:grid-cols-2 gap-5 text-center m-5 mt-7 md:mt-14">
        {services.map((service, index) => (
          <div
            key={index}
            className="flex flex-col items-center relative rounded-xl overflow-hidden group cursor-pointer"
          >
            <LazyLoadImage
              src={service.image}
              alt={service.name}
              className="absolute object-cover w-full h-full -z-10 brightness-50 group-hover:scale-105  transition-all duration-500 ease-in-out "
            />

            <content className="p-10 md:p-16 text-left">
              <div className="p-5 bg-white rounded-full w-fit">
                {service.icon}
              </div>
              <h3 className="text-2xl font-bold text-white mt-7">
                {service.name}
              </h3>
              <p className=" text-white mt-5 ">{service.description}</p>
            </content>
          </div>
        ))}
      </main>
    </div>
  );
}
