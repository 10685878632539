// This is a very complex rendering component

import PackageAdminScreen from "./sections/packages/packages";
import CareerAdminScreen from "./sections/career/career";
import { useState } from "react";

export default function AdminDashboard() {
  const availablePortals = [
    {
      id: 0,
      name: "Packages",
    },
    {
      id: 1,
      name: "Career",
    },
  ];

  const [selectedPortalId, setSelectedPortalId] = useState(
    availablePortals[0].id
  );

  return (
    <div>
      <div className="flex flex-col justify-center items-center p-5 text-center">
        <h1 className="text-4xl font-bold">Admin Dashboard</h1>
        <p className="text-xl mt-1">Welcome Albaca International Pvt Ltd.</p>

        <div>
          {availablePortals.map((portal, index) => (
            <button
              key={index}
              onClick={() => setSelectedPortalId(portal.id)}
              className={`${
                selectedPortalId === portal.id
                  ? "bg-secondary text-white"
                  : "bg-gray-200"
              } p-2 rounded-lg m-2 capitalize px-10 mt-11`}
            >
              {portal.name}
            </button>
          ))}
        </div>
      </div>
      {selectedPortalId === 0 ? <PackageAdminScreen /> : <CareerAdminScreen />}
    </div>
  );
}
