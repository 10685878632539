import React from "react";

function UploadResume({ handleFileChange }) {
  return (
    <div className="mt-9">
      <label className="form-control w-full max-w-xs">
        <input
          type="file"
          className="file-input file-input-bordered w-full max-w-xs"
          accept=".pdf"
          onChange={handleFileChange}
        />

        <div className="label">
          <span className="label-text-alt">only .pdf format is allowed.</span>
          <span className="label-text-alt">max size: 2MB.</span>
        </div>
      </label>
    </div>
  );
}

export default UploadResume;
