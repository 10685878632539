import OtherServices from "./sections/other_services/other_services";
import AppPromoSection from "./sections/app_promo/app_promo";
import BrowseTours from "./sections/browse_tours/browse_tours";
import ExplorePopularCities from "./sections/explore_popular_cities/explore_popular_cities";
import Gallery from "./sections/gallery/gallery";
import HeroSection from "./sections/hero_section/hero_section";
import Testimonial from "./sections/testimonial/testimonial";
import TextSection from "./sections/text_section/text_section";
import TrendingPackage from "./sections/trending/trending";

export default function HomePage() {
  return (
    <div className="overflow-x-hidden">
      <div className="">
        {/* hero section */}
        <HeroSection />

        {/* explore popular cities section */}
        <ExplorePopularCities />

        {/* browse domestic  */}
        <BrowseTours />

        {/* trending dubai */}
        <TrendingPackage />

        {/* browse domestic  */}
        <BrowseTours isDomestic={false} />

        {/* App promo */}
        <AppPromoSection />

        {/* Gallery */}
        <Gallery />

        {/* other services */}
        <OtherServices />

        {/* testimonial */}
        <Testimonial />

        {/* text section */}
        <TextSection />
      </div>
    </div>
  );
}
