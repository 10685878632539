import React from "react";
import { Slide } from "react-slideshow-image";
import ConstantVariables from "../constants/varaibles";

export default class Utils {
  // Slider function to display images in a slider
  // takes in an array of images and returns a slider component
  static Slider = (imageLinkList) => {
    return (
      <div className="">
        <Slide autoplay infinite arrows={false}>
          {imageLinkList.map((image, index) => (
            <div
              key={index}
              className="flex-shrink-0 w-full h-full snap-start object-cover"
            >
              <img src={image.src} alt={image.alt} />
            </div>
          ))}
        </Slide>
      </div>
    );
  };

  // Function to get the current month and year
  static getMonth = () => {
    const currentDate = new Date();
    const monthName = ConstantVariables.months[currentDate.getMonth()];
    const year = currentDate.getFullYear();
    return `${monthName.substring(0, 3)}, ${year.toString().substring(2, 4)}`;
  };

  // Function to get the current date or a date in the future in format of "6 June 2034"
  static getFormattedDate = (timestamp = Date.now(), daysToAdd = 0) => {
    const date = new Date(timestamp);
    date.setDate(date.getDate() + daysToAdd);

    const day = date.getDate();
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
  };

  // check if there is user

  static jobStructure = {
    id: 1,
    title: "Software Engineer",
    company: "Linear company",
    location: "Brussels",
    type: "Full time",
    salary: "$50-55k",
    logo: "https://images.unsplash.com/photo-1530893609608-32a9af3aa95c?q=80&w=1964&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",

    description:
      "Mollit in laborum tempor Lorem incididunt irure. Aute eu ex ad sunt. Pariatur sint culpa do incididunt eiusmod eiusmod culpa. Laborum tempor Lorem incididunt.",
  };

  static packageDataStructure = {
    package_id: "ALB001",
    package_name: "Enchanting Kerala",
    destination: "Munnar, Thekkady, Alleppey, India",
    package_type: "4 Nights, 5 Days",
    country: "India",
    duration: 5,
    price: 26106,
    gallery: [
      "https://images.unsplash.com/photo-1443397646383-16272048780e?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",

      "https://images.unsplash.com/photo-1499803270242-467f7311582d?q=80&w=1973&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",

      "https://images.unsplash.com/photo-1534789048561-fce25c3b7b68?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",

      "https://images.unsplash.com/photo-1615880484746-a134be9a6ecf?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",

      "https://images.unsplash.com/photo-1623718649591-311775a30c43?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",

      "https://images.unsplash.com/photo-1520250497591-112f2f40a3f4?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    ],
    itinerary: [
      {
        day: 1,
        description: [
          {
            info: "Arrival in Cochin via flight",
            imageLink:
              "https://images.unsplash.com/photo-1529074963764-98f45c47344b?q=80&w=1786&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            iconLink:
              "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2XL_CGjaMxFEn1wWpqG7VmdWQIfJyJ-4JPw&s",
          },
          {
            info: "Transfer from airport to hotel in Munnar and sightseeing 1 Hrs Valara Waterfalls",
            imageLink:
              "https://images.unsplash.com/photo-1432405972618-c60b0225b8f9?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            iconLink:
              "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2XL_CGjaMxFEn1wWpqG7VmdWQIfJyJ-4JPw&s",
          },
          {
            info: "Check-in to Hotel Munnar Hawk Hospitality Traditional Meal Included",
            imageLink:
              "https://images.unsplash.com/photo-1455587734955-081b22074882?q=80&w=1920&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            iconLink:
              "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2XL_CGjaMxFEn1wWpqG7VmdWQIfJyJ-4JPw&s",
          },
          {
            info: "Activity in Munnar Duration 1 Hrs Complimentary Guided spice Plantation Tour",
            imageLink:
              "https://images.unsplash.com/photo-1470087167738-6aa485ff65dc?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            iconLink:
              "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2XL_CGjaMxFEn1wWpqG7VmdWQIfJyJ-4JPw&s",
          },
        ],
      },
      {
        day: 2,
        description: [
          {
            info: "Private AC Sedan for Sightseeing in & around Munnar Duration 4Hrs Breakfast Included",
            imageLink:
              "https://images.unsplash.com/photo-1555215695-3004980ad54e?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            iconLink:
              "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2XL_CGjaMxFEn1wWpqG7VmdWQIfJyJ-4JPw&s",
          },
          {
            info: "Visit Ervikulam national park",
            imageLink:
              "https://images.unsplash.com/photo-1516926133025-705ee504386d?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            iconLink:
              "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2XL_CGjaMxFEn1wWpqG7VmdWQIfJyJ-4JPw&s",
          },
          {
            info: "Mattupetty Dam",
            imageLink:
              "https://images.unsplash.com/photo-1587020190382-d7112391d8cd?q=80&w=2069&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            iconLink:
              "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2XL_CGjaMxFEn1wWpqG7VmdWQIfJyJ-4JPw&s",
          },
          {
            info: "Echo Point",
            imageLink:
              "https://images.unsplash.com/photo-1610963813469-fad7bc78ebde?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            iconLink:
              "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2XL_CGjaMxFEn1wWpqG7VmdWQIfJyJ-4JPw&s",
          },
          {
            info: "Tata tea Museum",
            imageLink:
              "https://images.unsplash.com/photo-1513038630932-13873b1a7f29?q=80&w=1935&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            iconLink:
              "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2XL_CGjaMxFEn1wWpqG7VmdWQIfJyJ-4JPw&s",
          },
        ],
      },
      {
        day: 3,
        description: [
          {
            info: "Breakfast included at Hawk Hospitality",
            imageLink:
              "https://images.unsplash.com/photo-1596701062351-8c2c14d1fdd0?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            iconLink:
              "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2XL_CGjaMxFEn1wWpqG7VmdWQIfJyJ-4JPw&s",
          },
          {
            info: "Check out from Hotel in Munnar",
            imageLink:
              "https://images.unsplash.com/photo-1639598003170-acfd39fcd7b1?q=80&w=2069&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            iconLink:
              "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2XL_CGjaMxFEn1wWpqG7VmdWQIfJyJ-4JPw&s",
          },
          {
            info: "Transfer from Munnar to Thekkady private transfer 3 seater AC",
            imageLink:
              "https://images.unsplash.com/photo-1597685204565-110abf469a1e?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            iconLink:
              "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2XL_CGjaMxFEn1wWpqG7VmdWQIfJyJ-4JPw&s",
          },
          {
            info: "Visit Periyar Lake",
            imageLink:
              "https://images.unsplash.com/photo-1476514525535-07fb3b4ae5f1?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            iconLink:
              "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2XL_CGjaMxFEn1wWpqG7VmdWQIfJyJ-4JPw&s",
          },
          {
            info: "Check in Hotel Sandra Palace, Thekkady",
            imageLink:
              "https://images.unsplash.com/photo-1598902596597-728cb15eeb3f?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            iconLink:
              "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2XL_CGjaMxFEn1wWpqG7VmdWQIfJyJ-4JPw&s",
          },
        ],
      },
      {
        day: 4,
        description: [
          {
            info: "Check-out from hotel in Thekkady",
            imageLink:
              "https://images.unsplash.com/photo-1639598003170-acfd39fcd7b1?q=80&w=2069&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            iconLink:
              "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2XL_CGjaMxFEn1wWpqG7VmdWQIfJyJ-4JPw&s",
          },
          {
            info: "Transfer from Thekkady to Alleppey private transfer 3 seater AC",
            imageLink:
              "https://images.unsplash.com/photo-1597685204565-110abf469a1e?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            iconLink:
              "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2XL_CGjaMxFEn1wWpqG7VmdWQIfJyJ-4JPw&s",
          },
          {
            info: "Visit Alleppey beach",
            imageLink:
              "https://images.unsplash.com/photo-1507525428034-b723cf961d3e?q=80&w=2073&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            iconLink:
              "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2XL_CGjaMxFEn1wWpqG7VmdWQIfJyJ-4JPw&s",
          },
          {
            info: "Check in Hotel Venice Ica Residency Breakfast Included",
            imageLink:
              "https://images.unsplash.com/photo-1535825400335-401dbaeea8d2?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            iconLink:
              "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2XL_CGjaMxFEn1wWpqG7VmdWQIfJyJ-4JPw&s",
          },
          {
            info: "Activity in Alleppey 4Hrs",
            imageLink:
              "https://images.unsplash.com/photo-1595917513241-e9d7a9d8f0a0?q=80&w=1991&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            iconLink:
              "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2XL_CGjaMxFEn1wWpqG7VmdWQIfJyJ-4JPw&s",
          },
          {
            info: "Visit Hop-on Hop-off Houseboat Tour (Half-Day-Cruise)",
            imageLink:
              "https://images.unsplash.com/photo-1528034342377-c406327f14b7?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            iconLink:
              "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2XL_CGjaMxFEn1wWpqG7VmdWQIfJyJ-4JPw&s",
          },
        ],
      },
      {
        day: 5,
        description: [
          {
            info: "Check-out from hotel in Alleppey",
            imageLink:
              "https://images.unsplash.com/photo-1639598003170-acfd39fcd7b1?q=80&w=2069&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            iconLink:
              "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2XL_CGjaMxFEn1wWpqG7VmdWQIfJyJ-4JPw&s",
          },
          {
            info: "Return to Cochin for departure",
            imageLink:
              "https://images.unsplash.com/photo-1490430657723-4d607c1503fc?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            iconLink:
              "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2XL_CGjaMxFEn1wWpqG7VmdWQIfJyJ-4JPw&s",
          },
        ],
      },
    ],

    inclusions: [
      "Accommodation in 3-star resort",
      "Air Fare",
      "Daily breakfast",
      "All transfers and sightseeing as per itinerary",
      "English-speaking guide",
    ],
    exclusions: [
      "Travel Insurance",
      "Meals not specified in the itinerary",
      "Personal expenses",
    ],
    description: [
      "Nestled amidst the rolling hills of the Western Ghats in Tamil Nadu, Kodaikanal holds a distinguished place as one of India's most cherished tourist destinations. Its name, which translates to `The Gift of the Forest,` aptly captures the essence of this idyllic hill station.",
      "Kodaikanal's honor lies not only in its breathtaking natural beauty but also in its commitment to preserving its ecological sanctity. As travelers wander through the mist-covered valleys and verdant forests, they are met with a sense of reverence for the delicate balance of nature. From the majestic Silver Cascade waterfall to the tranquil Coaker's Walk, each sight evokes a deep appreciation for the wonders of the natural world.",
      "Beyond its scenic landscapes, Kodaikanal embodies a rich tapestry of cultural heritage. The town's colonial past is evident in its architecture, with quaint cottages and churches dotting the landscape. Visitors can delve into the region's history at the Kodaikanal Solar Observatory or immerse themselves in the vibrant local art scene at the Kodaikanal School of Arts and Crafts.",
      "Yet, perhaps the true honor of Kodaikanal lies in the warmth and hospitality of its people. From the bustling markets filled with local delicacies to the serene homestays tucked away in the hills, every interaction is infused with a sense of genuine kindness and hospitality.",
      "In essence, Kodaikanal stands as a testament to the enduring bond between humanity and nature. Its honor is not merely in its picturesque vistas or historical landmarks but in the profound sense of connection and harmony it fosters between people and the environment. As travelers depart, they carry with them not just memories of a beautiful destination but a renewed appreciation for the wonders of the natural world and the importance of preserving them for generations to come.",
    ],
  };
}
