import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import { LazyLoadImage } from "react-lazy-load-image-component";

function ContactUsScreen() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const emailServiceId = process.env.REACT_APP_EMAIL_SERVICE_ID;
  const emailContactTemplateId =
    process.env.REACT_APP_EMAIL_CONTACT_TEMPLATE_ID;

  const emailPublicId = process.env.REACT_APP_EMAIL_PUBLIC_KEY;

  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Add form submission logic here

    // Send email
    function sendEmail() {
      const templateParams = {
        from_name: name,
        user_email: email,
        user_phone_number: phone,
        user_message: message,
      };

      emailjs
        .send(
          emailServiceId,
          emailContactTemplateId,
          templateParams,

          {
            publicKey: emailPublicId,
          }
        )
        .then(
          () => {
            alert("Request recieved! We'll contact you soon.");
          },
          (error) => {
            // alert("FAILED... = " + error);
          }
        );
    }

    sendEmail();

    // Clear the form
    setName("");
    setEmail("");
    setPhone("");
    setMessage("");
  };

  return (
    <div className="container mx-auto max-w-screen-md lg:max-w-screen-xl   pt-6 md:p-6 lg:p-12 px-4 p-7">
      <div className="text-start">
        <h1 className="text-3xl font-bold">Get in Touch</h1>
        <p className="text-lg">We are always there for you</p>
      </div>

      <LazyLoadImage
        className="rounded-xl md:mt-5 mt-3  md:h-80 h-32 w-full object-cover shrink-0"
        src="https://images.unsplash.com/photo-1617348016197-bd031e297ce3?q=80&w=2073&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        alt="contact"
      />

      <div className="flex  flex-col lg:flex-row md:mt-7 mx-auto justify-between">
        <ReachOut />

        <div className="lg:w-[60%] border md:my-10 p-5 rounded-lg ">
          <h2 className="text-lg font-bold">Write to us</h2>
          <p className="mt-3 text-sm">
            If you are looking to resolve a lost & found issue, have any query,
            feedback, or complaint, fill in the form below, and we will respond
            to your request at the earliest possible.
          </p>
          <form onSubmit={handleFormSubmit} className="pt-9 ">
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="name"
                >
                  Name
                </label>
                <input
                  className=" block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4  outline-1 leading-tight focus:outline-none"
                  id="name"
                  type="text"
                  value={name}
                  onChange={(e) => {
                    const enteredName = e.target.value.replace(
                      /[^A-Za-z\s]/g,
                      ""
                    );
                    setName(enteredName);
                  }}
                  required
                />
              </div>
              <div className="w-full md:w-1/2 px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="email"
                >
                  Email
                </label>
                <input
                  className=" block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4  outline-1 leading-tight focus:outline-none"
                  id="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="phone"
                >
                  Phone
                </label>
                <input
                  maxLength={15}
                  className=" block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4  outline-1 leading-tight focus:outline-none"
                  id="phone"
                  type="tel"
                  value={phone}
                  onChange={(e) => {
                    const inputPhoneNumber = e.target.value.replace(/\D/g, "");
                    setPhone(inputPhoneNumber);
                  }}
                />
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="message"
                >
                  Message
                </label>
                <textarea
                  className=" block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4  outline-1 leading-tight focus:outline-none"
                  id="message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  required
                />
              </div>
            </div>

            {/* button */}
            <button
              className="bg-secondary_light hover:bg-secondary text-white font-bold py-2 px-4 rounded"
              type="submit"
            >
              Send Message
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

function ReachOut() {
  return (
    <div className="lg:w-[30%] p-4 py-10 my-10 border rounded-lg flex flex-col gap-2">
      <h2 className="text-xl font-bold text-left mb-4">Reach out to us</h2>

      <div className="">
        <div className="flex items-center mb-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 text-gray-400 mr-2"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m0 0l-7 7-7-7"
            />
          </svg>
          <p className="text-gray-600">Albaca International Pvt Ltd</p>
        </div>

        <p className="text-gray-600 mb-2">
          <strong>Registered office:</strong> No:4/124 B GST Road, Vandalur
          Chennai Tamilnadu 600 048.
        </p>
        <div className="flex items-center mb-2 mt-7">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 text-gray-400 mr-2"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 00-5.516 5.517l-1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1.28a1 1 0 01-1-.684l-1.498-4.493a1 1 0 01.502-1.21l2.257-1.13a11.042 11.042 0 005.516-5.517l1.13 2.257a1 1 0 01-1.21.502l-4.493-1.498a1 1 0 01-.684-.949V5z"
            />
          </svg>
          <a href="tel:1800-572-111111" className="text-blue-500">
            8122594639
          </a>
        </div>

        <div className="flex items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 text-gray-400 mr-2"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
            />
          </svg>
          <a href="mailto:groupbooking@adani.com" className="text-blue-500">
            support@albaca.in
          </a>
        </div>
      </div>
    </div>
  );
}

export default ContactUsScreen;
