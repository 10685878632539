// This is a very complex rendering component

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deletePackageThunk,
  fetchPackagesIdsThunk,
  handlePackageFormVisibility,
} from "../../../state/slices/admin/admin_slice";

import CustomForm from "./customForm";

export default function PackageAdminScreen() {
  const dispatch = useDispatch();

  const isFormOpen = useSelector((state) => state.adminSlice.isPackageFormOpen);

  const allPackagesData = useSelector(
    (state) => state.adminSlice.allPackagesData
  );

  const [currentEditingIndex, setCurrentEditingIndex] = useState(null);

  // getting all pacakge ids and name here here
  useEffect(() => {
    dispatch(fetchPackagesIdsThunk());
  }, [dispatch]);

  // handleEdit
  const handleEdit = (index) => {
    setCurrentEditingIndex(index);
    dispatch(handlePackageFormVisibility(true));
  };

  const handleDelete = (package_id) => {
    dispatch(deletePackageThunk(package_id));
  };

  return (
    <div className="max-w-screen-xl mx-auto p-5">
      {/* custom form */}
      {isFormOpen && (
        <CustomForm
          // if the intital data is an empty object then the form wont be prefilled
          initialData={
            currentEditingIndex !== null
              ? allPackagesData[currentEditingIndex]
              : {}
          }
        />
      )}

      {/* pacakges  */}

      <main className="py-10">
        <div>
          {/*  */}
          <div className="flex justify-between">
            <h2 className="font-bold text-lg bg-secondary text-white w-fit px-3 py-1 rounded-lg">
              All Packages
            </h2>

            <button
              className="border px-5 bg-green-500 text-white rounded-lg"
              onClick={() => {
                setCurrentEditingIndex(null);
                dispatch(handlePackageFormVisibility(true));
              }}
            >
              + Add
            </button>
          </div>

          <div className="pt-10 grid md:grid-cols-2 lg:grid-cols-3 gap-5">
            {
              allPackagesData.map((packageData, index) => (
                <div key={index} className="flex gap-5 border">
                  {/* thumbnail */}
                  <img
                    src={packageData.gallery[0]}
                    alt="thumbnail"
                    className="w-20 h-full shrink-0 object-cover"
                  />
                  <div className="p-5 pl-1">
                    <h3>
                      <span className="font-semibold">ID: </span>
                      {packageData.package_id}
                    </h3>
                    <h2 className="font-semibold text-xl">
                      {packageData.package_name}
                    </h2>

                    {/* buttons */}
                    <div className="mt-5 grid grid-cols-2 items-start gap-3 w-fit">
                      {/* EDIT button */}
                      <button
                        className="border border-secondary px-3 py-1 rounded-lg"
                        onClick={() => handleEdit(index)}
                      >
                        edit
                      </button>

                      {/* Delete Button */}
                      <button
                        className="bg-red-500 text-white px-3 py-1 rounded-lg"
                        onClick={() => {
                          handleDelete(packageData.package_id);
                        }}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              ))
              // Display the list of package ids here
            }
          </div>
        </div>
      </main>
    </div>
  );
}
