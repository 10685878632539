import React from "react";

const termsAndConditions = [
  "Please read these Terms and Conditions ('Terms') carefully before using www.albaca.in (the 'Website') operated by Albaca International Pvt Ltd ('Albaca International,' 'we,' 'us,' or 'our').",
  "By accessing or using the Website, you agree to be bound by these Terms. If you disagree with any part of these Terms, you may not access the Website.",
  {
    title: "Use of the Website",
    content: [
      "You must be at least 18 years old to use the Website. By using the Website, you represent and warrant that you are at least 18 years old.",
      "You agree to use the Website only for lawful purposes and in accordance with these Terms.",
      "You are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer or mobile device. You agree to accept responsibility for all activities that occur under your account or password.",
    ],
  },
  {
    title: "Intellectual Property",
    content: [
      "The Website and its original content, features, and functionality are owned by Albaca International and are protected by international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.",
      "You may not modify, reproduce, distribute, create derivative works of, publicly display, publicly perform, republish, download, store, or transmit any of the material on the Website, except as expressly permitted by these Terms.",
    ],
  },
  {
    title: "User Content",
    content: [
      "The Website may allow you to post, submit, publish, display, or transmit content ('User Content'). You are solely responsible for your User Content and the consequences of posting or publishing it.",
      "By posting or publishing User Content on the Website, you grant Albaca International a non-exclusive, worldwide, royalty-free, perpetual, irrevocable, sublicenseable, and transferable right to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and display such User Content in any form, media, or technology.",
    ],
  },
  {
    title: "Limitation of Liability",
    content: [
      "In no event shall Albaca International, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the Website; (ii) any conduct or content of any third party on the Website; (iii) any content obtained from the Website; and (iv) unauthorized access, use, or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence), or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.",
    ],
  },
  {
    title: "Governing Law",
    content: [
      "These Terms shall be governed by and construed in accordance with the laws of [insert jurisdiction], without regard to its conflict of law provisions.",
    ],
  },
  {
    title: "Changes to These Terms",
    content: [
      "We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will provide at least [insert notice period] days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.",
    ],
  },
  {
    title: "Contact Us",
    content: [
      "If you have any questions about these Terms, please contact us at support@albaca.in.",
    ],
  },
];

const renderPolicySection = (section) => {
  return (
    <div key={section.title}>
      <h3 className="font-bold p-3 px-0 pt-7">{section.title}</h3>
      <ul>
        {section.content.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    </div>
  );
};

const TermsAndConditions = () => {
  return (
    <div id="termsAndConditions" className="max-w-screen-md mx-auto py-10">
      <h2 className="text-3xl font-bold pb-10 ">Terms and Conditions</h2>
      {termsAndConditions.map((item, index) =>
        typeof item === "string" ? (
          <p key={index}>{item}</p>
        ) : (
          renderPolicySection(item)
        )
      )}
    </div>
  );
};

export default TermsAndConditions;
