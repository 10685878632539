import React, { useRef } from "react";
import { FaSmile, FaBox, FaConciergeBell, FaTrophy } from "react-icons/fa";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";
import CustomerReviews from "./customer_reviews";

export default function Testimonial() {
  const testimonials = [
    {
      title: "Happy Customers",
      value: 1000,
      icon: <FaSmile />,
    },
    {
      title: "Total Packages Sold",
      value: 200,
      icon: <FaBox />,
    },
    {
      title: "Services",
      value: 3,
      icon: <FaConciergeBell />,
    },
    {
      title: "Awards Won",
      value: 1,
      icon: <FaTrophy />,
    },
  ];

  return (
    <div
      className="mx-auto"
      style={{
        backgroundImage: "url(/images/dubai_bg.png)",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="max-w-screen-lg mx-auto p-9 md:py-16">
        <div className="text-center text-3xl font-bold">
          <h2 className="text-white">Testimonials</h2>
        </div>

        {/* stats */}
        <div className="grid grid-cols-1 mt-7 md:mt-16 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5 ">
          {testimonials.map((testimonial, index) => {
            return (
              <TestimonialItem
                key={index}
                testimonial={testimonial}
                index={index}
              />
            );
          })}
        </div>

        {/* reviews */}
        <CustomerReviews />
      </div>
    </div>
  );
}

function TestimonialItem({ testimonial, index }) {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <div
      ref={ref}
      className="flex p-5 md:p-9 border flex-col gap-10 md:gap-4 gap-x-7 items-center rounded-3xl justify-center shadow-lg bg-primary text-secondary_light mx-10 md:mx-0 py-10 "
    >
      {/* icons */}
      <div className="p-3 text-2xl md:text-4xl bg-gray-200 rounded-full">
        {testimonial.icon}
      </div>
      <div className="flex flex-col md:items-center md:mt-9 items-center justify-center gap-1">
        {/* title */}
        <div className="text-md">{testimonial.title}</div>

        {/* value */}
        <div className="text-4xl font-bold flex gap-1">
          {inView && <CountUp end={testimonial.value} duration={2}></CountUp>}
          {(index === 0 || index === 1) && inView && "+"}
        </div>
      </div>
    </div>
  );
}
