import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getGalleyImagesThunk } from "../../../../state/slices/home_page/home_page_slice";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function Gallery() {
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    dispatch(getGalleyImagesThunk());
  }, [dispatch]);

  const galleryLinks = useSelector(
    (state) => state.homePageSlice.galleryImageLinks
  );

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  // Determine the number of rows to display
  const imagesToShow = expanded ? galleryLinks : galleryLinks.slice(0, 3);

  return (
    <div className="max-w-screen-lg mx-auto py-7 md:py-16">
      <div className="p-5 text-center max-w-screen-md mx-auto relative">
        <h2 className="text-3xl font-bold">From The Gallery</h2>
        <p className="mt-3 md:text-lg">
          Unveil the World's Wonders with Our Expertly Curated Packages. Your
          Gateway to Unforgettable Adventures.
        </p>
      </div>
      {/* gallery */}

      <div className="mt-5 md:mt-14">
        {/* button */}
        <div className="w-full flex items-end justify-end px-5 mx-auto ">
          <button
            onClick={handleExpand}
            className="flex items-center border-2 border-gray-300 text-gray-600 text-md px-4 py-1 text-sm rounded-full font-medium "
          >
            {expanded ? "Show Less" : "Show More"}
          </button>
        </div>

        <main
          className="grid grid-cols-1 md:grid-cols-3
       p-5 gap-5 "
        >
          {imagesToShow.map((link, index) => (
            <div key={index} className="overflow-hidden rounded-lg ">
              <LazyLoadImage
                src={link}
                alt="gallery"
                className="brightness-90  aspect-square  object-cover hover:scale-105  transition-all duration-500 ease-in-out"
                style={{ backfaceVisibility: "hidden" }}
              />
            </div>
          ))}
        </main>
      </div>
    </div>
  );
}
