import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

const AboutUsData = [
  {
    title: "Albaca International Vision",
    text: "At Albaca International, our mission is to empower individuals and businesses to achieve their full potential by providing innovative and effective digital solutions.",
    image:
      "https://images.unsplash.com/photo-1552664730-d307ca884978?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    title: "Albaca International Purpose",
    text: "Our vision is to be the leading digital solutions provider, known for our expertise, creativity, and commitment to delivering exceptional results.",
    image:
      "https://images.unsplash.com/photo-1440778303588-435521a205bc?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
];

const AboutUsScreen = () => {
  return (
    <div className="mx-auto max-w-screen-xl pb-10">
      <div className="py-8 pb-0 px-5">
        <h1 className="text-3xl font-bold">About Us</h1>

        <p>Discover Our Story</p>
      </div>
      <div className="flex flex-col">
        {/* image */}
        <LazyLoadImage
          className=" xl:rounded-xl md:mt-10 mt-3  md:h-96 h-32 w-full object-cover shrink-0"
          src="https://images.unsplash.com/photo-1501785888041-af3ef285b470?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          alt="beach"
        />

        {/* content */}
        <div className="flex flex-col md:gap-y-20 mt-5 md:mt-10">
          {AboutUsData.map((item, index) => (
            <div
              key={index}
              className={`flex flex-col ${
                index % 2 ? "md:flex-row-reverse" : "md:flex-row"
              } p-5 gap-7 items-center justify-between`}
            >
              <div className="md:w-[50%]">
                <h2 className="font-bold text-xl">{item.title}</h2>
                <p className="mt-3">{item.text}</p>
              </div>

              <LazyLoadImage
                src={item.image}
                alt={item.title}
                className="rounded-lg md:aspect-video md:w-[40%] h-auto object-cover"
              />
            </div>
          ))}
        </div>

        {/* about the company */}
        <div className="p-5 py-9 border m-5 mt-10 rounded-lg shadow-lg bg-[linear-gradient(to_right,#ad3d77,#6a275d)] text-white">
          <h2 className="text-xl font-bold">We Albaca International,</h2>
          <p className="mt-5">
            An integrated platform with diversified offerings, Albaca
            International is where excellence begins. It is built to elevate
            everyday experiences by combining comfort, quality, and ease-of-use
            for our customers. From door-to-door travel to shopping the choicest
            global brands, this is where it all manifests together, with
            seamless digital access. Remembering the Group’s core philosophy of
            ‘Growth with Goodness’, Albaca International stays true to the aim
            of enriching experiences with goodness, through this singular but
            multifaceted world. The Group has been dedicated towards
            contributing to growth through its various businesses over the
            years. In the digital era, Albaca International is another step
            forward in this journey, of making goodness a part of our customer’s
            life, anywhere, any time.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUsScreen;
