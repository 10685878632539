// THIS FILE IS VERY IMPORTANT
// This file is responsible for handling the user authentication state
// It is also responsible for handling the user data
// Dont modify this file unless you know what you are doing

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { auth } from "../../../firebase_config/firebase";
import {
  signInWithPopup,
  GoogleAuthProvider,
  signInWithRedirect,
} from "firebase/auth";

const initialState = {
  user: null,
  loading: false,
  isAuthScreenOpen: false,
};

// Asynchronous thunk action
export const googleSignInThunk = createAsyncThunk(
  "authSlice/googleSignIn",
  async (_, { dispatch, rejectWithValue }) => {
    // Google authentication provider
    const provider = new GoogleAuthProvider();

    try {
      const result = await signInWithPopup(auth, provider);
      dispatch(updateAuthScreenVisibleStatus(false));
      return result.user;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const authSlice = createSlice({
  name: "authSlice",
  initialState,
  reducers: {
    updateUserData: (state, action) => {
      state.user = action.payload;
    },

    updateLoadingStatus: (state, action) => {
      state.loading = action.payload;
    },

    updateAuthScreenVisibleStatus: (state, action) => {
      if (action.payload === true) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
      state.isAuthScreenOpen = action.payload;
    },

    signOut(state) {
      state.user = null;
      auth.signOut();
    },
  },

  extraReducers: (builder) => {
    builder.addCase(googleSignInThunk.fulfilled, (state, action) => {
      state.user = action.payload;

      // dispatc
    });

    builder.addCase(googleSignInThunk.rejected, (state, action) => {
      console.log(action.payload);
    });

    builder.addCase(googleSignInThunk.pending, (state, action) => {
      console.log("Pending");
    });
  },
});

export const {
  updateUserData,
  updateLoadingStatus,
  updateAuthScreenVisibleStatus,
  signOut,
} = authSlice.actions;
export default authSlice.reducer;
