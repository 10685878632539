import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import _ from "lodash";
import {
  addNewPackageThunk,
  handlePackageFormVisibility,
} from "../../../state/slices/admin/admin_slice";

// Define the color as a variable
const separatorColor = "#F3F4F6";

// Reusable Input Component
const InputField = ({ label, type = "text", value, onChange }) => (
  <div className="mb-4 p-5" style={{ backgroundColor: separatorColor }}>
    <label className="block text-sm font-bold mb-2">{label}</label>
    <input
      type={type}
      value={value}
      onChange={onChange}
      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
    />
  </div>
);

// Reusable TextArea Component
const TextAreaField = ({ label, value, onChange }) => (
  <div className="mb-4 p-5" style={{ backgroundColor: separatorColor }}>
    <label className="block text-sm font-bold mb-2">{label}</label>
    <textarea
      value={value}
      onChange={onChange}
      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-24"
    ></textarea>
  </div>
);

// Reusable ImageField Component
const ImageField = ({ label, value, onChange }) => (
  <div className="mb-4 p-5" style={{ backgroundColor: separatorColor }}>
    <label className="block text-sm font-bold mb-2">{label}</label>
    <input
      type="url"
      value={value}
      onChange={onChange}
      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
    />
  </div>
);

// Main Form Component
export default function CustomForm({ initialData }) {
  const [formData, setFormData] = React.useState({
    package_id: "",
    package_name: "",
    destination: "",
    package_type: "",
    country: "",
    duration: "",
    price: "",
    gallery: [""],
    itinerary: [
      { day: "", description: [{ info: "", imageLink: "", iconLink: "" }] },
    ],
    inclusions: [""],
    exclusions: [""],
    description: [""],
  });

  const handleChange = (field, index, subIndex, subField, value) => {
    setFormData((prevFormData) => {
      const updatedFormData = _.cloneDeep(prevFormData);

      if (
        field === "gallery" ||
        field === "inclusions" ||
        field === "exclusions" ||
        field === "description"
      ) {
        updatedFormData[field][index] = value;
      } else if (field === "itinerary") {
        if (!updatedFormData.itinerary[index]) {
          updatedFormData.itinerary[index] = { day: "", description: [] };
        }
        if (subField === "day") {
          updatedFormData.itinerary[index].day = value;
        } else {
          if (!updatedFormData.itinerary[index].description[subIndex]) {
            updatedFormData.itinerary[index].description[subIndex] = {
              info: "",
              imageLink: "",
              iconLink: "",
            };
          }
          updatedFormData.itinerary[index].description[subIndex][subField] =
            value;
        }
      } else {
        updatedFormData[field] = value;
      }

      return updatedFormData;
    });
  };

  const addField = (field) => {
    setFormData((prevFormData) => {
      const updatedFormData = _.cloneDeep(prevFormData);

      if (
        field === "gallery" ||
        field === "inclusions" ||
        field === "exclusions" ||
        field === "description"
      ) {
        updatedFormData[field].push("");
      } else if (field === "itinerary") {
        updatedFormData.itinerary.push({
          day: "",
          description: [{ info: "", imageLink: "", iconLink: "" }],
        });
      }

      return updatedFormData;
    });
  };

  const addDescription = (index) => {
    setFormData((prevFormData) => {
      const updatedFormData = _.cloneDeep(prevFormData);

      if (!updatedFormData.itinerary[index].description) {
        updatedFormData.itinerary[index].description = [];
      }
      updatedFormData.itinerary[index].description.push({
        info: "",
        imageLink: "",
        iconLink: "",
      });

      return updatedFormData;
    });
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (Object.keys(initialData).length > 0) {
      setFormData(initialData);
    }
  }, [initialData]);

  // HANDLE SUBMIT
  const handleSubmit = (e) => {
    e.preventDefault();

    // Function to deep compare two objects
    function deepCompare(obj1, obj2) {
      return JSON.stringify(obj1) === JSON.stringify(obj2);
    }

    // Function to filter empty strings from arrays within the object
    function filterEmptyStrings(object) {
      const obj = { ...object }; // Create a shallow copy to avoid mutating the original object

      for (let key in obj) {
        if (Array.isArray(obj[key])) {
          if (key === "itinerary") {
            obj[key] = obj[key]
              .map((day) => {
                // Filter out descriptions with all empty fields
                day.description = day.description.filter((description) => {
                  return Object.values(description).some(
                    (value) => typeof value === "string" && value.trim() !== ""
                  );
                });
                return day;
              })
              .filter((day) => day.description.length > 0);
          } else {
            obj[key] = obj[key].filter(
              (item) => typeof item !== "string" || item.trim() !== ""
            );
          }
        }
      }

      return obj;
    }

    // Applying the filtering function to formData

    // Check if formData is different from initialData
    if (!deepCompare(formData, initialData)) {
      const optimizedFormData = filterEmptyStrings(formData);
      dispatch(addNewPackageThunk(optimizedFormData));
    } else {
      alert("No changes detected."); // Optional: Handle case where no changes are detected
    }
  };

  return (
    <div className="fixed w-screen flex flex-col justify-center items-center mx-auto inset-0 p-3 md:p-10 ">
      <div
        className="fixed w-screen flex flex-col justify-center items-center mx-auto inset-0 bg-black p-3 md:p-10 bg-opacity-35"
        onClick={() => dispatch(handlePackageFormVisibility(false))}
      ></div>
      <form
        onSubmit={handleSubmit}
        className="bg-white w-full md:w-[40%] h-full overflow-auto z-10"
      >
        <h1 className="text-3xl mb-8 p-5">Package Form</h1>
        <InputField
          label="Package ID"
          value={formData.package_id}
          onChange={(e) =>
            handleChange("package_id", null, null, null, e.target.value)
          }
        />
        <InputField
          label="Package Name"
          value={formData.package_name}
          onChange={(e) =>
            handleChange("package_name", null, null, null, e.target.value)
          }
        />
        <InputField
          label="Destination"
          value={formData.destination}
          onChange={(e) =>
            handleChange("destination", null, null, null, e.target.value)
          }
        />
        <InputField
          label="Package Type"
          value={formData.package_type}
          onChange={(e) =>
            handleChange("package_type", null, null, null, e.target.value)
          }
        />
        <InputField
          label="Country"
          value={formData.country}
          onChange={(e) =>
            handleChange("country", null, null, null, e.target.value)
          }
        />
        <InputField
          label="Duration"
          type="number"
          value={formData.duration}
          onChange={(e) =>
            handleChange("duration", null, null, null, e.target.value)
          }
        />
        <InputField
          label="Price"
          type="number"
          value={formData.price}
          onChange={(e) =>
            handleChange("price", null, null, null, e.target.value)
          }
        />

        <div className="mb-4 p-5" style={{ backgroundColor: separatorColor }}>
          <label className="block text-sm font-bold mb-2">Gallery</label>
          {formData.gallery.map((url, index) => (
            <ImageField
              key={index}
              label={`Image ${index + 1}`}
              value={url}
              onChange={(e) =>
                handleChange("gallery", index, null, null, e.target.value)
              }
            />
          ))}
          <button
            type="button"
            className="bg-secondary_light text-white py-2 px-4 rounded mt-2"
            onClick={() => addField("gallery")}
          >
            Add Image
          </button>
        </div>

        <div className="mb-4 p-5" style={{ backgroundColor: separatorColor }}>
          <h2 className="text-2xl mb-4">Itinerary</h2>
          <button
            type="button"
            className="bg-secondary_light text-white py-2 px-4 rounded mb-4"
            onClick={() => addField("itinerary")}
          >
            Add Day
          </button>
          {formData.itinerary.map((itineraryItem, index) => (
            <div
              key={index}
              className="mb-4 p-5"
              style={{ backgroundColor: separatorColor }}
            >
              <InputField
                label={`Day ${index + 1}`}
                value={itineraryItem.day}
                onChange={(e) =>
                  handleChange("itinerary", index, null, "day", e.target.value)
                }
              />
              {itineraryItem.description.map((desc, descIndex) => (
                <div
                  key={descIndex}
                  className="mb-4 p-5"
                  style={{ backgroundColor: separatorColor }}
                >
                  <TextAreaField
                    label={`Description ${descIndex + 1}`}
                    value={desc.info}
                    onChange={(e) =>
                      handleChange(
                        "itinerary",
                        index,
                        descIndex,
                        "info",
                        e.target.value
                      )
                    }
                  />
                  <InputField
                    label="Image Link"
                    value={desc.imageLink}
                    onChange={(e) =>
                      handleChange(
                        "itinerary",
                        index,
                        descIndex,
                        "imageLink",
                        e.target.value
                      )
                    }
                  />
                  <InputField
                    label="Icon Link"
                    value={desc.iconLink}
                    onChange={(e) =>
                      handleChange(
                        "itinerary",
                        index,
                        descIndex,
                        "iconLink",
                        e.target.value
                      )
                    }
                  />
                </div>
              ))}
              <button
                type="button"
                className="bg-secondary_light text-white py-2 px-4 rounded mt-2"
                onClick={() => addDescription(index)}
              >
                Add Description
              </button>
            </div>
          ))}
        </div>

        <div className="mb-4 p-5" style={{ backgroundColor: separatorColor }}>
          <label className="block text-sm font-bold mb-2">Inclusions</label>
          {formData.inclusions.map((item, index) => (
            <InputField
              key={index}
              label={`Inclusion ${index + 1}`}
              value={item}
              onChange={(e) =>
                handleChange("inclusions", index, null, null, e.target.value)
              }
            />
          ))}
          <button
            type="button"
            className="bg-secondary_light text-white py-2 px-4 rounded mt-2"
            onClick={() => addField("inclusions")}
          >
            Add Inclusion
          </button>
        </div>

        <div className="mb-4 p-5" style={{ backgroundColor: separatorColor }}>
          <label className="block text-sm font-bold mb-2">Exclusions</label>
          {formData.exclusions.map((item, index) => (
            <InputField
              key={index}
              label={`Exclusion ${index + 1}`}
              value={item}
              onChange={(e) =>
                handleChange("exclusions", index, null, null, e.target.value)
              }
            />
          ))}
          <button
            type="button"
            className="bg-secondary_light text-white py-2 px-4 rounded mt-2"
            onClick={() => addField("exclusions")}
          >
            Add Exclusion
          </button>
        </div>

        <div className="mb-4 p-5" style={{ backgroundColor: separatorColor }}>
          <label className="block text-sm font-bold mb-2">Description</label>
          {formData.description.map((item, index) => (
            <TextAreaField
              key={index}
              label={`Description ${index + 1}`}
              value={item}
              onChange={(e) =>
                handleChange("description", index, null, null, e.target.value)
              }
            />
          ))}
          <button
            type="button"
            className="bg-secondary_light py-2 px-4 text-white rounded mt-2"
            onClick={() => addField("description")}
          >
            Add Description
          </button>
        </div>

        <div className="flex items-center justify-between p-10">
          <button
            onClick={(e) => {
              e.preventDefault();
              dispatch(handlePackageFormVisibility(false));
            }}
            className="border-2 border-red-500 text-red-500 py-2 px-10 rounded-full focus:outline-none focus:shadow-outline"
          >
            Cancle
          </button>
          <button
            type="submit"
            className="bg-secondary text-white py-2 px-10 rounded-full focus:outline-none focus:shadow-outline"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
}
