import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getDocs,
  collection,
  startAfter,
  limit,
  query,
} from "firebase/firestore";
import { db } from "../../../firebase_config/firebase"; // Make sure this is your actual path to the Firebase configuration

// Define the maximum number of documents to fetch per request
const PAGE_SIZE = 5;

export const getAllPackagesThunk = createAsyncThunk(
  "allPackagesSlice/getAllPackages",
  async ({ lastDoc }, { rejectWithValue }) => {
    try {
      const packagesRef = collection(db, "packages");
      let q;

      if (lastDoc) {
        q = query(packagesRef, startAfter(lastDoc), limit(PAGE_SIZE));
      } else {
        q = query(packagesRef, limit(PAGE_SIZE));
      }

      const querySnapshot = await getDocs(q);
      const packages = [];
      querySnapshot.forEach((doc) => {
        packages.push({ id: doc.id, ...doc.data() });
      });

      const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];

      return { packages, lastDoc: lastVisible };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  allPackages: [],
  lastDoc: null,
  loading: false,
  error: null,
};

// Helper function to filter out duplicate packages by id
const filterUniquePackages = (existingPackages, newPackages) => {
  const existingPackageIds = new Set(existingPackages.map((pkg) => pkg.id));
  return newPackages.filter((pkg) => !existingPackageIds.has(pkg.id));
};

const allPackagesSlice = createSlice({
  name: "allPackagesSlice",
  initialState,
  reducers: {
    resetPackagesState: (state) => {
      state.allPackages = [];
      state.lastDoc = null;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllPackagesThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllPackagesThunk.fulfilled, (state, action) => {
        state.loading = false;
        const uniquePackages = filterUniquePackages(
          state.allPackages,
          action.payload.packages
        );
        state.allPackages = [...state.allPackages, ...uniquePackages];
        state.lastDoc = action.payload.lastDoc;
      })
      .addCase(getAllPackagesThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { resetPackagesState } = allPackagesSlice.actions;
export default allPackagesSlice.reducer;
