import React from "react";
import Utils from "../../../../utils/utils";
import HomePageConstants from "../../../../constants/home_page_constants";
import DesktopSlider from "./sub_components/desktop_ui";
import HeroMobileSlider from "./sub_components/mobile_ui";

export default function HeroSection() {
  const [destination, setDestination] = React.useState("Anywhere");
  const [month, setMonth] = React.useState(Utils.getMonth());
  const [packages, setPackages] = React.useState("All Packages");

  const sliderImagesList =
    HomePageConstants.HeroSectionConstants.imageSliderImagesList;

  return (
    <div>
      {/* Mobile Slider */}
      <HeroMobileSlider
        sliderImagesList={sliderImagesList}
        destination={destination}
        setDestination={setDestination}
      />

      {/* Desktop Slider */}
      <DesktopSlider
        sliderImagesList={sliderImagesList}
        destination={destination}
        setDestination={setDestination}
      />
    </div>
  );
}
