import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineCalendar, AiOutlineCar, AiOutlineTeam } from "react-icons/ai";
import { FaStar } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import { getAllPackagesThunk } from "../../state/slices/all_packages/all_packages_slice";

export default function AllPackagesPage() {
  const dispatch = useDispatch();
  const { lastDoc, loading, error } = useSelector(
    (state) => state.allPackagesSlice
  );
  const allPackages = useSelector(
    (state) => state.allPackagesSlice.allPackages
  );

  const observer = useRef(null);
  const lastPackageElementRef = useRef(null);
  const location = useLocation();
  const [query, setQuery] = useState(null);

  useEffect(() => {
    dispatch(getAllPackagesThunk({ lastDoc: null }));
  }, [dispatch]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const q = searchParams.get("q");
    setQuery(q ? q.toLowerCase() : null);
  }, [location.search]);

  useEffect(() => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && !loading && lastDoc) {
        dispatch(getAllPackagesThunk({ lastDoc }));
      }
    });
    if (lastPackageElementRef.current) {
      observer.current.observe(lastPackageElementRef.current);
    }
    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [loading, lastDoc, dispatch]);

  const filteredPackages = allPackages.filter((tour) => {
    if (!query || query === "anywhere" || query === "all") return true; // Show all packages if no query
    if (query === "anywhere" || query === "all") return true; // Show all packages if no query
    return (
      tour.package_name.toLowerCase().includes(query) ||
      tour.destination.toLowerCase().includes(query) ||
      tour.country.toLowerCase().includes(query)
    );
  });

  return (
    <div className="max-w-screen-xl mx-auto py-3">
      {query && (
        <div className="bg-gray-50 p-5 mb-10">
          <h1 className="">Search Results for: "{query}"</h1>
          {/* {filteredPackages.length < 1 ? (
            <p className="text-sm mt-3 font-light">
              No packages found matching the query.
            </p>
          ) : (
            ""
          )} */}
        </div>
      )}
      <div className="p-5 flex flex-col justify-center items-center mb-10">
        <h2 className="text-2xl tracking-widest font-semibold text-secondary">
          EXPLORE
        </h2>
        <h2 className="text-3xl font-bold mt-1 text-gray-800">
          All Holiday Packages
        </h2>
      </div>
      <div className="relative">
        <div className="grid md:grid-cols-2 gap-5 p-5">
          {filteredPackages.length > 0
            ? filteredPackages.map((tour, index) => (
                <PackageDesignModel
                  key={tour.package_id}
                  tour={tour}
                  ref={
                    index === filteredPackages.length - 1
                      ? lastPackageElementRef
                      : null
                  }
                />
              ))
            : allPackages.map((tour, index) => (
                <PackageDesignModel
                  key={tour.package_id}
                  tour={tour}
                  ref={
                    index === allPackages.length - 1
                      ? lastPackageElementRef
                      : null
                  }
                />
              ))}
        </div>
      </div>
      {loading && <p></p>}
      {error && <p>Error: {error}</p>}
    </div>
  );
}

const PackageDesignModel = React.forwardRef(({ tour }, ref) => {
  const features = [
    {
      title: "Flexible Booking",
      icon: <AiOutlineCalendar />,
    },
    {
      title: "Transport Facility",
      icon: <AiOutlineCar />,
    },
    {
      title: "Family Plan",
      icon: <AiOutlineTeam />,
    },
  ];

  const reviews = () => {
    return (
      <div className="flex items-center justify-start">
        <div className="text-sm flex items-center gap-1">
          <div className="flex">
            {Array.from({ length: 5 }).map((_, index) => (
              <FaStar key={index} size={13} color="#ffa432" />
            ))}
          </div>
          <p>
            {"( "}
            {Math.floor(Math.random() * (1000 - 300 + 1)) + 300} reviews {" )"}
          </p>
        </div>
      </div>
    );
  };

  return (
    <div
      ref={ref}
      className="w-full flex shadow-md border max-w-screen-md mx-auto flex-shrink-0 cursor-pointer group"
    >
      <Link to={`/packages/${tour.package_id}`} className="flex">
        <div className="relative overflow-hidden">
          <img
            src={tour.gallery[0]}
            alt="tour_image"
            className="w-44 md:w-52 h-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out"
          />
        </div>
        <div className="pl-5 pr-3 py-3 w-full">
          {reviews()}
          <h3 className="text-lg md:text-xl font-semibold mt-3">
            {tour.package_name}
          </h3>
          <div className="flex flex-col md:flex-row gap-3 pt-3">
            {features.map((feature) => (
              <div
                key={feature.title}
                className="flex gap-3 items-center text-sm md:border-r-2 pr-3"
              >
                {feature.icon}
                <p>{feature.title}</p>
              </div>
            ))}
          </div>
          <div className="h-0.5 mt-5 w-full bg-gray-200"></div>
          <div className="flex justify-center md:justify-end">
            <div className="mt-2">
              <p className="text-xs font-bold text-[#7bbcb0] uppercase">
                starting for just
              </p>
              <p className="text-lg font-semibold">
                ₹ {tour.price} <span className="text-sm">/person</span>
              </p>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
});
