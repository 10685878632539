import React from "react";

const privacyPolicy = [
  {
    title: "Data Protection",
    content: [
      "We employ industry-standard encryption and security measures to safeguard your personal information.",
      "Your data is stored on secure servers and accessed only by authorized personnel for legitimate purposes.",
      "We adhere to strict data protection protocols to ensure the confidentiality and integrity of your information.",
    ],
  },
  {
    title: "Information Collection",
    content: [
      "We collect personal data such as name, contact information, and demographic details to personalize your experience and improve our services.",
      "Information may be gathered through forms, cookies, or interaction with our website or mobile application.",
      "We may also collect usage data to analyze trends and enhance user experience.",
    ],
  },
  {
    title: "Security Measures",
    content: [
      "Our security measures include firewalls, data encryption, and regular security audits to protect against unauthorized access or breaches.",
      "Access to your personal information is restricted to authorized personnel who require it for legitimate business purposes.",
      "We continually update our security protocols to mitigate risks and ensure the safety of your data.",
    ],
  },
  {
    title: "Third-Party Disclosure",
    content: [
      "We may share your information with trusted third-party service providers for specific purposes such as payment processing, analytics, or marketing.",
      "These third parties are bound by strict confidentiality agreements and are prohibited from using your information for any other purpose.",
      "We do not sell or rent your personal information to third parties without your explicit consent.",
    ],
  },
  {
    title: "Cookie Usage",
    content: [
      "We use cookies to enhance website functionality, personalize your experience, and analyze site traffic.",
      "You can control cookie preferences through your browser settings and opt-out of certain types of cookies if desired.",
      "By continuing to use our website, you consent to the use of cookies as described in our Cookie Policy.",
    ],
  },
  {
    title: "Opt-Out Options",
    content: [
      "You have the right to opt-out of certain data collection and processing activities, such as marketing communications or targeted advertising.",
      "You can update your preferences or opt-out by contacting us directly or using the opt-out mechanisms provided in our communications.",
    ],
  },
  {
    title: "Policy Updates",
    content: [
      "We reserve the right to update or modify this privacy policy at any time to reflect changes in our practices or legal requirements.",
      "Updates will be posted on our website, and your continued use of our services constitutes acceptance of the revised policy.",
    ],
  },
  {
    title: "Children's Privacy",
    content: [
      "We are committed to protecting the privacy of children under the age of 13 and do not knowingly collect or store personal information from minors.",
      "If you believe that we have inadvertently collected information from a child, please contact us immediately, and we will take appropriate measures to address the issue.",
    ],
  },
  {
    title: "Contact Information",
    content: [
      "If you have any questions, concerns, or requests regarding your privacy or data, please contact us at support@albaca.in.",
      "We are committed to addressing your inquiries promptly and transparently.",
    ],
  },
  {
    title: "Compliance with Regulations",
    content: [
      "We comply with applicable laws and regulations governing data protection and privacy, including but not limited to the IT Act, 2000 (21 of 2000).",
      "Our data processing activities are conducted in accordance with legal requirements and industry best practices to ensure compliance and protect your rights.",
    ],
  },
];

const cookiesPolicy = [
  {
    title: "Introduction",
    content: [
      "Cookies are small pieces of data stored on your device (computer or mobile device) when you visit a website. They are widely used to make websites work more efficiently and to provide information to website owners.",
    ],
  },
  {
    title: "Purpose of Cookies",
    content: [
      {
        subtitle: "Authentication",
        description:
          "To recognize you when you visit the Website and to authenticate your access to certain features or services.",
      },
      {
        subtitle: "Personalization",
        description:
          "To personalize your experience on the Website, such as remembering your preferences or settings.",
      },
      {
        subtitle: "Analytics",
        description:
          "To gather information about how you use the Website, such as which pages you visit and how long you spend on each page. This helps us analyze and improve the Website's performance and usability.",
      },
      {
        subtitle: "Advertising",
        description:
          "To display targeted advertisements to you based on your browsing behavior and interests.",
      },
    ],
  },
  {
    title: "Types of Cookies We Use",
    content: [
      {
        subtitle: "Essential Cookies",
        description:
          "These cookies are necessary for the Website to function properly. They enable basic functions like page navigation and access to secure areas of the Website. Without these cookies, the Website may not function correctly.",
      },
      {
        subtitle: "Performance Cookies",
        description:
          "These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our Website. They help us understand which pages are the most and least popular and see how visitors move around the site.",
      },
      {
        subtitle: "Functionality Cookies",
        description:
          "These cookies enable the Website to provide enhanced functionality and personalization. They may be set by us or by third-party providers whose services we have added to our pages.",
      },
      {
        subtitle: "Advertising Cookies",
        description:
          "These cookies are used to deliver advertisements that are relevant to you and your interests. They may be used by third-party advertising networks to track your browsing activity across different websites.",
      },
    ],
  },
  {
    title: "Managing Cookies",
    content: [
      "You can control and/or delete cookies as you wish. You can delete all cookies that are already on your device and you can set most browsers to prevent them from being placed. If you do this, however, you may have to manually adjust some preferences every time you visit the Website and some services and functionalities may not work.",
    ],
  },
  {
    title: "Changes to This Cookies Policy",
    content: [
      "We may update our Cookies Policy from time to time. Any changes we make to our Cookies Policy will be posted on this page with a new 'Last Updated' date. We encourage you to review this Cookies Policy periodically for any changes.",
    ],
  },
  {
    title: "Contact Us",
    content: [
      "If you have any questions about these Privacy Policies, please contact us at support@albaca.in.",
    ],
  },
];

const renderPolicySection = (section) => {
  return (
    <div
      key={section.title}
      className="py-3"
      id={section.title.replace(/\s+/g, "")}
    >
      <h3 className="font-bold text-lg mb-2">{section.title}</h3>
      {section.content.map((item, index) =>
        typeof item === "string" ? (
          <p key={index} className="leading-snug">
            {item}
          </p>
        ) : (
          <div key={index} className="pl-5 p-2">
            <h4 className="font-bold text-md">{item.subtitle}</h4>
            <p className="leading-snug">{item.description}</p>
          </div>
        )
      )}
    </div>
  );
};

const renderPolicy = (policy) => {
  return policy.map((section, index) => renderPolicySection(section));
};

const Policies = () => {
  return (
    <div className="max-w-screen-md mx-auto py-10">
      <div id="privacyPolicy">
        <h2 className="font-bold text-3xl pb-5">Privacy Policy</h2>
        {renderPolicy(privacyPolicy)}
      </div>
      <div id="cookiesPolicy">
        <h2 className="font-bold text-3xl py-5">Cookies Policy</h2>
        {renderPolicy(cookiesPolicy)}
      </div>
    </div>
  );
};

export default Policies;
