import React, { useEffect, useState } from "react";
import { FaRocket, FaUserClock } from "react-icons/fa";
import emailjs from "@emailjs/browser";

import { GrLocation } from "react-icons/gr";
import UploadResume from "./sections/upload_resume/upload_resume";
import { useDispatch, useSelector } from "react-redux";
import { updateAuthScreenVisibleStatus } from "../../state/slices/auth/authSlice";
import { getAllJobsThunk } from "../../state/slices/career/careerSlice";
import { storage } from "../../firebase_config/firebase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";

import { LazyLoadImage } from "react-lazy-load-image-component";

// CareerScreen component to render a list of jobs
export default function CareerScreen() {
  const dispatch = useDispatch();
  const [isResumeUploaded, setIsResumeUploaded] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploadedResumeUrl, setUploadedResumeUrl] = useState(null);
  const user = useSelector((state) => state.authSlice.user);

  // Array of job data
  const jobs = useSelector((state) => state.careerSlice.allJobs);

  useEffect(() => {
    dispatch(getAllJobsThunk());
  }, [dispatch]);

  // handle file change
  const handleFileChange = (event) => {
    const maxSize = 2 * 1024 * 1024; //max size 2 mb;
    // const maxSize = 50 * 1024; // 50 KB in bytes
    const file = event.target.files[0];
    if (file) {
      const fileName = file.name;
      const fileType = file.type;
      if (fileType === "application/pdf") {
        // hold the file in state
        // check for max file size
        if (file.size > maxSize) {
          alert("File size exceeds 2MB. Please upload a smaller file.");
        } else {
          setUploadedFile(file);
          console.log(`Uploaded file: ${fileName}`);
        }
      } else {
        alert("Please upload a PDF file.");
        // Optionally clear the input value
        event.target.value = null;
      }
    } else {
      // Clear the uploaded file and reset the state if the user either deselects
      // or removes the uploaded file

      setUploadedFile(null);
    }
  };

  // handle Apply
  // send data with email
  const [appliedJobs, setAppliedJobs] = useState([]);

  // handle Apply
  const emailServiceId = process.env.REACT_APP_EMAIL_SERVICE_ID_2;
  const emailCareerTemplateId = process.env.REACT_APP_EMAIL_CAREER_TEMPLATE_ID;
  const emailPublicId = process.env.REACT_APP_EMAIL_PUBLIC_KEY_2;
  const handleApply = (job) => {
    if (appliedJobs.includes(job.id)) {
      return;
    } else {
      setAppliedJobs((prev) => [...prev, job.id]);

      if (uploadedFile) {
        // if the resume is not yet uploaded ( upload )
        if (uploadedResumeUrl == null) {
          // user name
          const userName = user.displayName.replaceAll(" ", "_");

          // uploading to bucket
          const storageRef = ref(storage, `resumes/${userName}`);
          uploadBytes(storageRef, uploadedFile)
            .then((snapshot) => {
              getDownloadURL(snapshot.ref).then((url) => {
                // capturing the resume url
                setUploadedResumeUrl(url);
                // send email
                const templateParms = {
                  from_name: user.displayName,
                  user_email: user.email,
                  user_resume: uploadedResumeUrl,
                  job_id: job.id,
                  job_title: job.title,
                  job_company: job.company,
                  job_salary: job.salary,
                  job_type: job.type,
                  job_location: job.location,
                };

                emailjs.send(
                  emailServiceId,
                  emailCareerTemplateId,
                  templateParms,
                  {
                    publicKey: emailPublicId,
                  }
                );
              });
            })
            .catch((error) => {
              console.error("Error uploading file: ", error);
            });
        }
        // if resume is already uploaded and link is available
        // send email
        else {
          // send email
          const templateParms = {
            from_name: user.displayName,
            user_email: user.email,
            user_resume: uploadedResumeUrl,
            job_id: job.id,
            job_title: job.title,
            job_company: job.company,
            job_salary: job.salary,
            job_type: job.type,
            job_location: job.location,
          };

          emailjs.send(emailServiceId, emailCareerTemplateId, templateParms, {
            publicKey: emailPublicId,
          });
        }
      } else {
        alert("No file selected!");
      }
    }
  };

  // handle resume upload

  // uploaded file is held in a variable name uploadedFile
  const handleResumeUpload = () => {
    setIsResumeUploaded(true);
  };

  //  resume upload container.
  // 1. shows  upload container ot upload the resume
  // 2. shows the success message if the resume is uploaded
  const ResumeUploadingContainer = () => (
    <div className=" max-w-screen-xl mx-5 ">
      <div
        className={`m-5 shadow-sm w-full mx-auto p-5  ${
          isResumeUploaded ? "bg-teal-100" : "bg-orange-50"
        }`}
      >
        <div className="flex gap-3 items-center">
          <FaRocket size={22} className="text-orange-400" />
          <div>
            <h1 className="text-2xl font-bold">
              {isResumeUploaded ? "Great! Resume Uploaded" : "Upload Resume"}
            </h1>
          </div>
        </div>
        <div className="pl-9">
          <p className="mt-5 ">
            {!isResumeUploaded && (
              <h2 className="font-semibold">Looking for a job?</h2>
            )}
            <p
              className="mt-2 max-w-screen-md"
              dangerouslySetInnerHTML={{
                __html: isResumeUploaded
                  ? "You are now eligible to begin applying for positions that align with your qualifications and experience.<br/> Best of luck in your job search!"
                  : "Take the first step in your application process by uploading your resume now! Once uploaded, you can start applying for jobs and connecting with employers eager to find someone like you.",
              }}
            ></p>
          </p>

          {/* resume ulpload fields */}
          {!isResumeUploaded && (
            <div className="flex flex-col lg:flex-row gap-5 items-start lg:items-center">
              <UploadResume handleFileChange={handleFileChange} />
              {/* upload resume button */}
              <button
                className={` py-1.5 px-3 w-fit h-fit ${
                  uploadedFile === null
                    ? "bg-gray-200 text-gray-500 disabled:cursor-not-allowed"
                    : "bg-orange-400  text-white"
                }  rounded-xl`}
                disabled={uploadedFile === null}
                onClick={handleResumeUpload}
              >
                Upload Resume
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );

  // sign in contaner displayed if the user is not signed in..
  const SignInContainer = () => (
    <div className=" max-w-screen-xl mx-5 ">
      <div className={`m-5 shadow-sm w-full mx-auto p-5 bg-orange-100`}>
        <div className="flex flex-col gap-5">
          <h2 className="text-xl font-semibold">
            Sign in to start applying for jobs!
          </h2>
          {/* sign in button */}
          <button
            className={` py-1.5 px-3 w-fit h-fit bg-secondary  text-white  rounded-xl`}
            onClick={() => {
              dispatch(updateAuthScreenVisibleStatus(true));
            }}
          >
            Sign in
          </button>
        </div>
      </div>
    </div>
  );

  // Main component rendering JobCard components
  return (
    <div className="py-3 pt-0 md:pt-5 md:py-10">
      <div className="max-w-screen-xl mx-auto">
        <div className="flex flex-col">
          {/* UPLOAD RESUME container */}
          {user === null ? <SignInContainer /> : <ResumeUploadingContainer />}

          {/* job card */}
          <div className="grid lg:grid-cols-2 gap-4">
            {jobs.map((job) => (
              <JobCard
                key={job.id}
                job={job}
                isResumeUploaded={isResumeUploaded}
                handleApply={() => handleApply(job)}
                appliedJobs={appliedJobs}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

// JobCard component to render individual job details
const JobCard = ({ job, isResumeUploaded, handleApply, appliedJobs }) => {
  const isApplied = appliedJobs.includes(job.id);
  return (
    <div className="flex flex-col p-4 max-w-4xl mx-auto h-full w-full">
      <div className="relative border bg-white rounded-lg shadow-sm p-6 flex flex-col h-full space-y-4">
        {/* Apply button */}
        <button
          className={`absolute top-5 right-5 py-1.5 px-3 rounded-xl 
            ${
              isApplied
                ? "bg-green-500 text-white"
                : isResumeUploaded
                ? "bg-secondary_light text-white"
                : "hidden"
            }`}
          disabled={isApplied}
          onClick={() => handleApply(job.id)}
        >
          {isApplied ? "Applied" : "Apply Now"}
        </button>

        {/* Company logo */}
        <LazyLoadImage
          src={job.logo}
          alt="Company Logo"
          className="w-16 h-16 rounded-md object-cover shrink-0"
        />

        <div className="flex flex-col flex-grow">
          <div className="flex items-center justify-between w-full">
            <h2 className="text-xl font-semibold">{job.title}</h2>
          </div>
          <p className="text-gray-500 text-sm">{job.company}</p>
          <div className="flex flex-wrap items-center text-gray-500 text-sm mt-2 gap-x-5">
            <span className="flex items-center space-x-1">
              <GrLocation />
              <span>{job.location}</span>
            </span>
            <span className="flex items-center space-x-1">
              <FaUserClock />
              <span>{job.type}</span>
            </span>
            <span className="flex items-center space-x-1">
              <i className="fas fa-dollar-sign"></i>
              <span>{job.salary}</span>
            </span>
            <span className="flex items-center space-x-1">
              <span>{job.posted}</span>
            </span>
          </div>
          <p className="text-gray-500 text-sm mt-4">{job.description}</p>
        </div>
      </div>
    </div>
  );
};
