import { Link, useLocation } from "react-router-dom";
import CustomIcons from "../../constants/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  updateAuthScreenVisibleStatus,
  signOut,
} from "../../state/slices/auth/authSlice";
import { useEffect, useRef, useState } from "react";
import { FaHome } from "react-icons/fa";

const commonIconColor = "text-gray-700";
const navItems = [
  {
    icon: <FaHome />,

    name: "Home",
    link: "/",
  },
  {
    icon: CustomIcons.beach(commonIconColor),

    name: "Holiday Packages",
    link: "/packages",
  },
  {
    icon: CustomIcons.work(commonIconColor),
    name: "Career",
    link: "/career",
  },
  {
    icon: CustomIcons.support(commonIconColor),
    name: "Contact us",
    link: "/contact-us",
  },
  {
    icon: CustomIcons.about(`${commonIconColor}`),
    name: "About us",
    link: "/about-us",
  },
];

export default function HeroNavbar(props) {
  const user = useSelector((state) => state.authSlice.user);
  const userLoading = useSelector((state) => state.authSlice.loading);

  const location = useLocation();
  const isAdmin = location.pathname === "/admin";

  return (
    <div>
      {/* // if admin dont show the nav */}
      <div className={`${isAdmin ? "hidden" : ""}`}>
        <MobileNav user={user} userLoading={userLoading} />
        <DesktopNav user={user} userLoading={userLoading} />
      </div>
      {props.children}
    </div>
  );
}

// ================== MOBILE NAV   ==================
function MobileNav({ user, userLoading }) {
  // side pannel
  const [isSidePannelOpen, setIsSidePannelOpen] = useState(false);
  const dispatch = useDispatch();

  // open and close side pannel
  const openSidePannel = () => {
    setIsSidePannelOpen(true);
    document.body.style.overflow = "hidden";
  };
  const closeSidePannel = () => {
    setIsSidePannelOpen(false);
    document.body.style.overflow = "auto";
  };

  const sidePannl = () => {
    return (
      // this is the parent container of the side pannel
      <div
        className={`fixed  w-full h-full z-50 inset-0 ${
          isSidePannelOpen ? "" : "opacity-0 pointer-events-none"
        } transition-opacity duration-300 ease-in-out`}
      >
        {/* // this is the black background of the side pannel which one clicked closes the side pannel*/}
        <div
          className="absolute bg-black bg-opacity-30 w-full h-full" // close the side pannel
          onClick={() => closeSidePannel()}
        ></div>
        {/* // side pannel content */}
        {/* this is the actual slider which holds the content */}
        <div
          className={`relative bg-white w-2/3 h-full transform transition-transform duration-300 ${
            isSidePannelOpen ? "" : "-translate-x-full"
          }`}
        >
          <div className="flex gap-5 flex-col  font-semibold">
            {/* ================= SIGN IN OPTION ============== */}
            <div className="p-3 py-7 bg-gray-100">
              {/* if auth is loading show nothing */}
              {userLoading ? (
                <div> </div>
              ) : // if there is user show his photo
              user ? (
                <div className="flex flex-col items-center gap-5">
                  <img
                    src={user.photoURL}
                    alt="user_photo"
                    className="object-cover rounded-full w-20 h-20 mx-auto border"
                  />
                  <p className="text-gray-700">{user.displayName}</p>

                  {/* log out button */}
                  <button
                    className="border border-red-500 w-full py-2 px-7 text-red-500 rounded-full"
                    onClick={() => dispatch(signOut())}
                  >
                    Log out
                  </button>
                </div>
              ) : (
                // if there is no user show sign in button
                <div className="">
                  <div className="p-3 bg-gray-300 w-fit mx-auto rounded-full">
                    {CustomIcons.person("mx-auto text-gray-600", 33)}
                  </div>
                  <div
                    className="mt-5 border-2 border-secondary_light text-secondary_light hover:bg-secondary_light hover:text-white text-center py-2  rounded-full transition-colors duration-300 ease-in-out"
                    onClick={() => {
                      closeSidePannel();
                      // show the login options
                      dispatch(updateAuthScreenVisibleStatus(true));
                    }}
                  >
                    Sign in
                  </div>
                </div>
              )}
            </div>

            {/* side bar nav contents */}
            <div className="flex flex-col">
              {navItems.map((item, index) => {
                return (
                  <Link
                    to={item.link}
                    onClick={() => closeSidePannel()}
                    key={index}
                    className={
                      "flex gap-5 items-center cursor-pointer transition-all duration-300 ease-in-out border border-gray-50 py-5 px-3 hover:bg-secondary_light hover:bg-opacity-30 hover:shadow-md group"
                    }
                  >
                    <div className="group-hover:bg-white p-1 rounded-full">
                      {item.icon}
                    </div>
                    <div className="">{item.name}</div>
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
        ;
      </div>
    );
  };

  return (
    <nav className="z-10 w-full top-5 flex justify-between px-5 py-3 items-center mb-5 shadow-md min-[950px]:hidden">
      <Link to="/">
        <img
          src="/images/logo/logo_colored.png"
          alt="logo"
          className="w-36"
        ></img>
      </Link>

      {/* open menu button */}
      <p onClick={() => openSidePannel()}>{CustomIcons.menu()}</p>

      {sidePannl()}
    </nav>
  );
}

// ================== DESKTOP NAV   ==================
function DesktopNav({ user, userLoading }) {
  // make sure that the nv is only transparent on the home page

  const location = useLocation();
  const dispatch = useDispatch();
  const isHomePage = location.pathname === "/";

  // Company Logos
  const homePageLogo = "/images/logo/logo_white.png";
  const otherPagesLogo = "/images/logo/logo_colored.png";

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="hidden min-[950px]:block">
      {/* this is the nav */}
      <nav
        className={` fixed z-[999] w-full
        ${
          isHomePage && !isScrolled
            ? "absolute  p-7 bg-transparent"
            : "py-3 bg-white "
        }   
         ${isScrolled ? "shadow-md" : isHomePage ? "shadow-none" : "shadow-sm"}

          justify-between px-9 flex transition-all linear duration-300`}
      >
        <Link to="/">
          <img
            src={`${
              isHomePage
                ? isScrolled
                  ? otherPagesLogo
                  : homePageLogo
                : otherPagesLogo
            }`}
            alt="logo"
            className="w-48"
          />
        </Link>
        <ul
          className={`flex gap-3 ${
            isHomePage ? "text-white" : "text-gray-900"
          } font-semibold items-center`}
        >
          {navItems.map((item, index) => {
            return (
              <Link to={item.link}>
                <li
                  key={index}
                  className={`${
                    isHomePage
                      ? isScrolled
                        ? "hover:bg-secondary hover:text-primary text-gray-900 bg-opacity-100"
                        : "hover:bg-primary hover:text-gray-800"
                      : "hover:bg-secondary hover:text-white"
                  } h-fit p-1 px-3 rounded-lg cursor-pointer transition-all duration-300 ease-in-out`}
                >
                  {item.name}
                </li>
              </Link>
            );
          })}

          {/* Profile Button */}

          {
            <li>
              {/* if user is loading display nothing */}
              {userLoading ? (
                <div></div>
              ) : // if there is user show his photo
              user ? (
                <div className="relative group">
                  <div className="w-10 h-10 rounded-full overflow-hidden border hover:border-secondary">
                    <img
                      src={user.photoURL}
                      alt="user"
                      className="object-cover cursor-pointer"
                    />
                  </div>

                  {/* log out button container */}
                  <div className="hidden pt-3 absolute group-hover:block right-0">
                    {/* logout button */}
                    <button
                      className="bg-primary text-red-500 px-5 py-2 hover:bg-red-500 hover:text-white rounded-md shadow-lg whitespace-nowrap transition-all duration-300 ease-in-out border-2 border-red-500"
                      onClick={() => dispatch(signOut())}
                    >
                      Log Out
                    </button>
                  </div>
                </div>
              ) : (
                // if there is no user show sign in button
                <p
                  className={`border h-fit p-1 px-3 rounded-lg cursor-pointer transition-all duration-300 ease-in-out ${
                    isHomePage
                      ? isScrolled
                        ? "text-gray-900 border-gray-900 hover:bg-secondary hover:text-primary"
                        : "text-white border-white hover:bg-primary hover:text-gray-900"
                      : "text-secondary bg-primary hover:bg-secondary hover:text-white border-2"
                  }`}
                  onClick={(e) => {
                    dispatch(updateAuthScreenVisibleStatus(true));
                  }}
                >
                  Sign in
                </p>
              )}
            </li>
          }
        </ul>
      </nav>

      {/* this is some extra height to make sure it doesnt overlap the content */}
      {/* dont show this on the home page cause we want to put the nav bar above the hero images
      with respect to design */}
      {!isHomePage && <div className="h-20 "></div>}
    </div>
  );
}
