import { Link, useNavigate } from "react-router-dom";
import CustomIcons from "../../../../../constants/icons";
import HeroNavbar from "../../../../navbar/nav_bar";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function HeroMobileSlider({
  sliderImagesList,
  destination,
  setDestination,
}) {
  const navigate = useNavigate();
  function CustomFormElementOuterStyle({ children }) {
    return (
      <div className="flex justify-between items-center border border-gray-400 rounded-lg px-3 p-0 pt-2">
        {children}
      </div>
    );
  }
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  return (
    <div className="min-[950px]:hidden">
      {/* === nav bar */}
      {/* <HeroNavbar /> */}
      {/* form  */}
      <div className="p-3.5 h-fit">
        <main className="p-5 rounded-2xl shadow-md py-5 px-5 border bg-primary">
          {/* TITLE */}
          <div>
            {CustomIcons.beach("mr-1 scale-125 text-accent")}
            <h1 className="font-bold text-3xl">Holidays</h1>
          </div>

          {/* FORM */}
          <form action="#" className="mt-7 flex flex-col  gap-3.5">
            {/* Search Destination */}
            <div className="flex justify-between items-center border border-gray-400 rounded-lg px-3 pt-2">
              <div>
                <label htmlFor={"destination"} className="pt-1">
                  Search your dream destination
                </label>
                <input
                  className="border-none focus:border-none focus:outline-none py-2 pt-1 text-xl font-semibold w-4/5"
                  type="text"
                  placeholder="Search.."
                  onKeyDown={handleKeyDown}
                  onChange={(e) => setDestination(e.target.value)}
                  // name="destination"
                />
              </div>
              {CustomIcons.location("mr-1 scale-125")}
            </div>

            {/* Select Month */}
            {/* <CustomFormElementOuterStyle>
              <div>
                <label htmlFor="month" className="pt-1">
                  Select Month
                </label>
                <h3 className={inputStyleClasses}>{month}</h3>
              </div>
              {CustomIcons.calendar()}
            </CustomFormElementOuterStyle> */}

            {/* Select Stay Duration */}
            {/* <CustomFormElementOuterStyle>
              <div>
                <label htmlFor="packages" className="pt-1">
                  Select Stay Duration
                </label>
                <h3 className={inputStyleClasses}>{packages}</h3>
              </div>
              {CustomIcons.arrowDown()}
            </CustomFormElementOuterStyle> */}

            <Link
              to={`/packages?q=${destination}`} // search query
              className="flex justify-center gap-2 mt-7 w-full bg-secondary_light px-5 py-3.5 rounded-3xl text-white font-semibold hover:bg-secondary transition-all duration-50 ease-in-out"
            >
              <p>Search</p>
            </Link>
          </form>
        </main>
      </div>
      {/* ==== bottom scrollable images */}
      <div className="flex overflow-x-auto no-scrollbar">
        {sliderImagesList.map((image, index) => (
          <div
            key={index}
            className={`relative flex-shrink-0 h-48 w-auto aspect-video overflow-hidden rounded-xl snap-start object-cover m-5 shadow-md ${
              index === sliderImagesList.length - 1 ? "mr-5" : "mr-0"
            }`}
          >
            {image.type === "image" ? (
              <LazyLoadImage
                src={image.src}
                alt={image.alt}
                className="filter brightness-55 absolute inset-0 w-full h-full object-cover"
              />
            ) : (
              // if video return the video tag
              <video
                src={image.src}
                alt={image.alt}
                className={`h-48 filter brightness-75 w-full object-cover`}
                autoPlay={true}
                loop
                muted
              ></video>
            )}
            <div className="absolute inset-0 bg-black opacity-30"></div>
            <div className="absolute text-white inset-0 m-auto p-5 w-full h-fit">
              <p className="font-bold text-2xl line-clamp-2">
                {image.info.title}
              </p>
              {/* subtitle */}
              <p className="line-clamp-1">{image.info.subtitle}</p>

              <div className="flex items-baseline gap-3 gap-y-1 mt-1.5 flex-col-reverse">
                {/* dont show the button for index 4 & 1 */}
                {index !== 4 && index !== 3 && (
                  <button
                    className="capitalize text-black bg-white px-5 py-2 mt-3 rounded-full"
                    onClick={() => {
                      // if its a link redirect
                      const redirect = image.info.redirect;
                      if (redirect.includes("/")) {
                        navigate(image.info.redirect);
                      }
                      // if its ID then scroll to it
                      else {
                        const targetElement = document.getElementById(redirect);
                        if (targetElement) {
                          targetElement.scrollIntoView({
                            behavior: "smooth",
                            block: "start",
                          });
                        }
                      }
                    }}
                  >
                    {image.info.button_name.split("_").join(" ")}
                  </button>
                )}
                {/* coupon code */}
                <p>{image.info.coupon ? `Code : ${image.info.coupon}` : ""}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
