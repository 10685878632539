"use client";

import { getAuth } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";

import { getDatabase, ref, set, get, update } from "firebase/database";
import { useDispatch } from "react-redux";
import {
  updateLoadingStatus,
  updateUserData,
} from "../state/slices/auth/authSlice";
import AuthScreen from "./auth_screen/auth_screen";

// Create an HOC named "withAuth" to handle authentication check
// and pass the authenticated state as a prop to the wrapped component.

export default function AuthValidatorWrapper(props) {
  const dispatch = useDispatch();
  const auth = getAuth();

  const [user, loading, error] = useAuthState(auth);
  if (loading) {
    dispatch(updateLoadingStatus(true));
  }
  if (error) {
    dispatch(updateLoadingStatus(true));
  }

  if (!user && !loading) {
    dispatch(updateLoadingStatus(false));
  }

  if (user) {
    dispatch(updateLoadingStatus(false));
    dispatch(updateUserData(user));
  }

  return <AuthScreen>{props.children}</AuthScreen>;
}
