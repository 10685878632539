class HomePageConstants {
  static HeroSectionConstants = class {
    static get HERO_SECTION_TITLE() {
      return "Welcome to the Hero Section";
    }

    static get HERO_SECTION_subtitle() {
      return "This is the Hero Section subtitle";
    }

    static imageSliderImagesList = [
      {
        src: "images/taj_mahal.jpeg",
        alt: "Image 1",
        type: "image",

        info: {
          title: "The Timeless Creation of Taj Mahal",
          subtitle: "Divine Deals on Hotels, Holidays & Flights",
          button_name: "book_now",
          redirect: "/packages/ALB002",
        },
      },

      {
        src: "images/river.jpeg",
        alt: "Image 3",
        type: "image",
        info: {
          title: "Pathway to paradise!",
          subtitle: "Upto 5% off on Domestic Trips",
          button_name: "book_now",
          coupon: "SAVE5DOMESTIC",
          redirect: "top_domestic_packages",
        },
      },
      {
        src: "videos/plane_above_clouds.mp4",
        type: "video",
        alt: "Image 4",
        info: {
          title: "Journey beyond borders!",
          subtitle: "Upto 10% off on International Trips",
          button_name: "book_now",
          coupon: "SAVE5INTL",

          redirect: "top_international_packages",
        },
      },
      {
        src: "images/boat_luxury.jpeg",
        alt: "Image 2",
        type: "image",

        info: {
          title: "Experience LUXURY with every booking!",
          subtitle: "Get Assured Gifts & Special offers!",
          button_name: "know_more",
          redirect: "/",
        },
      },
      {
        src: "images/friends.jpeg",
        type: "image",
        alt: "Image 5",
        info: {
          title: "Refer friends and be rewarded!",
          subtitle: "Invite a friend and Get ₹100.",

          button_name: "invite_friends_now",

          redirect: "/",
        },
      },
    ];
  };
}
// src: "https://images.unsplash.com/photo-1495837174058-628aafc7d610?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",

export default HomePageConstants;
