import { useEffect, useRef, useState } from "react";
import CustomIcons from "../../../constants/icons";
import Dropdown from "react-dropdown";
import Utils from "../../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { updateAuthScreenVisibleStatus } from "../../../state/slices/auth/authSlice";
import "react-calendar/dist/Calendar.css";
import Calendar from "react-calendar";
import classNames from "classnames";
import emailjs from "@emailjs/browser";
import { FaCheck } from "react-icons/fa";

export default function BookFrom() {
  const dispatch = useDispatch();
  const packageData = useSelector((state) => state.singlePackage.packageData);
  const user = useSelector((state) => state.authSlice.user);
  const options = [1, 2, 3, 4, 5];
  const defaultOption = options[0];
  const getNextDay = new Date(Date.now() + 86400000);

  const emailServiceId = process.env.REACT_APP_EMAIL_SERVICE_ID;
  const emailQuoteTemplateId = process.env.REACT_APP_EMAIL_QUOTE_TEMPLATE_ID;
  const emailPublicId = process.env.REACT_APP_EMAIL_PUBLIC_KEY;

  const [showCalendar, setShowCalendar] = useState({
    checkIn: false,
    checkOut: false,
  });

  const calendarRef = useRef(null);

  const handleClickOutside = (event) => {
    if (calendarRef.current && !calendarRef.current.contains(event.target)) {
      setShowCalendar({
        checkIn: false,
        checkOut: false,
      });
    }
  };

  useEffect(() => {
    if (showCalendar.checkIn || showCalendar.checkOut) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showCalendar]);

  //   form state
  const [formData, setFormData] = useState({
    checkInDate: Utils.getFormattedDate(Date.now(), 1),
    checkOutDate: Utils.getFormattedDate(Date.now(), 5),
    numberOfGuests: defaultOption,
  });

  const [quoteRequested, setQuoteRequested] = useState(false);
  const [value, setValue] = useState(getNextDay);

  const onChange = (date) => {
    // Check if the date is an array (indicating a range selection)
    if (Array.isArray(date)) {
      setValue(date);
    } else {
      // Calculate the next 5 dates from the selected date
      const startDate = date;
      const endDate = new Date(date);
      endDate.setDate(startDate.getDate() + 4);
      setValue([startDate, endDate]);
      setFormData({
        ...formData,
        checkInDate: Utils.getFormattedDate(startDate),
        checkOutDate: Utils.getFormattedDate(endDate),
      });
    }
  };

  // phone number
  const [phoneNumber, setPhoneNumber] = useState("");

  // link copy state
  const [linkCopied, setLinkCopied] = useState(false);

  // copy handler
  const copyUrlToClipboard = () => {
    navigator.clipboard.writeText(window.location.href);
    setLinkCopied(true);
    // Reset the button text after 3 seconds
  };

  return (
    <div className="lg:w-[30%] mt-5 lg:mt-0 px-5  ">
      <div
        className={`rounded-xl rounded-tl-none rounded-tr-none  pb-9 shadow-md border-2`}
      >
        <h1
          className={`text-2xl font-semibold bg-[linear-gradient(to_right,_#ad3d77,_#6a275d)]  text-white py-3 px-3`}
        >
          Book Now
        </h1>

        {/* Add more content for booking here */}
        <form className="flex flex-col gap-7 mt-7 px-5 select-none">
          {/* 1st */}
          <div className="flex flex-col gap-2 ">
            <p>Check In Date</p>
            <div
              className="relative w-full flex justify-between border p-3 px-2 shadow-sm  bg-[#f4f4f5] cursor-pointer"
              onClick={() => {
                setShowCalendar({
                  checkIn: !showCalendar.checkIn,
                  checkOut: false,
                });
              }}
            >
              <p>{formData.checkInDate}</p>
              {CustomIcons.calendar("w-6 h-6")}

              {showCalendar.checkIn && (
                <div className="absolute z-[1050] top-full pt-1 w-full md:w-max right-0 left-0">
                  <Calendar
                    onChange={onChange}
                    value={value}
                    minDate={getNextDay}
                  />
                </div>
              )}
            </div>
          </div>
          {/* 2nd */}
          <div className="flex flex-col gap-2">
            <p>Check out Date</p>
            <div
              className="relative w-full flex justify-between border p-3 px-2 bg-[#f4f4f5]  cursor-pointer"
              onClick={() => {
                setShowCalendar({
                  checkIn: false,
                  checkOut: !showCalendar.checkOut,
                });
              }}
            >
              <p> {formData.checkOutDate}</p>
              {CustomIcons.calendar("w-6 h-6")}
              <div className="absolute z-[1050] top-full pt-1 w-full md:w-max right-0 left-0">
                {showCalendar.checkOut && (
                  <Calendar
                    onChange={onChange}
                    value={value}
                    minDate={getNextDay}
                  />
                )}
              </div>
            </div>
          </div>
          {/* 3rd */}
          <div className="flex flex-col gap-2">
            <p>Number of Guests</p>
            <Dropdown
              className="bg-[#f4f4f5]"
              options={options}
              onChange={(e) => {
                setFormData({ ...formData, numberOfGuests: e.value });
              }}
              value={defaultOption.toString()}
              placeholder="Select an option"
            />
          </div>

          {/* price container  */}
          <div className="flex flex-col justify-center items-center">
            <p className="font-semibold text-gray-600">Subtotal</p>

            <div className="flex flex-col items-center mt-3">
              {/* discount */}
              <div className="flex items-center gap-2">
                <p className="text-xl  line-through">
                  ₹
                  {(packageData.price * formData.numberOfGuests * 1.1).toFixed(
                    2
                  )}
                </p>
                <p className="text-[#ad3d77] text-2xl font-semibold">10% off</p>
              </div>
              {/* final price */}
              <p className="font-semibold text-2xl mt-1">
                ₹{packageData.price * formData.numberOfGuests}
              </p>
              <p className="font-semibold"></p>
            </div>
          </div>

          {/* phone number input */}
          {user && !quoteRequested && (
            <div className="flex justify-center items-center bg-gray-100">
              <input
                value={phoneNumber}
                maxLength={15}
                onChange={(e) => {
                  // Ensure only numbers are entered
                  const inputPhoneNumber = e.target.value.replace(/\D/g, "");
                  setPhoneNumber(inputPhoneNumber);
                }}
                type="tel"
                className="w-full max-w-md px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-[#ad3d77] focus:border-transparent"
                placeholder="Enter your phone number"
              />
            </div>
          )}

          {/* button  GET QUOTE*/}
          <button
            className={classNames(
              "py-3 rounded-md text-center border transition-all duration-300 ease-in-out",
              {
                "bg-gray-400 text-gray-100":
                  phoneNumber === "" && user !== null,
                "bg-white text-[#ad3d77] cursor-auto border-[#ad3d77]":
                  quoteRequested,
                "bg-[linear-gradient(to_right,#ad3d77,#6a275d)] hover:-translate-y-1 shadow-md hover:shadow-lg text-white":
                  (!quoteRequested && phoneNumber !== "" && user !== null) ||
                  user === null,
              }
            )}
            // quote requested on click
            onClick={(e) => {
              e.preventDefault();

              if (user) {
                if (!quoteRequested && phoneNumber !== "") {
                  setQuoteRequested(true);

                  //   send email here

                  function sendEmail() {
                    const templateParams = {
                      from_name: user.displayName,
                      user_email: user.email,
                      user_phone_number: phoneNumber,
                      package_id: packageData.package_id,
                      package_name: packageData.package_name,
                      check_in_date: formData.checkInDate,
                      check_out_date: formData.checkOutDate,
                      number_of_guests: formData.numberOfGuests,
                      package_price: packageData.price,
                      total_package_price:
                        packageData.price * formData.numberOfGuests,
                    };

                    emailjs
                      .send(
                        emailServiceId,
                        emailQuoteTemplateId,
                        templateParams,

                        {
                          publicKey: emailPublicId,
                        }
                      )
                      .then(
                        () => {
                          // alert("SUCCESS!");
                        },
                        (error) => {
                          // alert("FAILED... = " + error);
                        }
                      );
                  }

                  sendEmail();
                }
              } else {
                // dispatch google sign in
                dispatch(updateAuthScreenVisibleStatus(true));
              }
            }}
          >
            {quoteRequested ? "Query Sent" : "Send Query"}
          </button>

          {/* message */}
          <p className="text-gray-600 text-center">
            Request a Quote and our Executive will reach you soon.
          </p>

          {/* Share button */}

          <button
            onClick={(e) => {
              e.preventDefault();
              copyUrlToClipboard();
            }}
            className={`flex items-center justify-center gap-3 py-3 rounded-md text-center border ${
              linkCopied
                ? "border-teal-500 text-teal-500"
                : "border-[#ad3d77] text-[#ad3d77]"
            }`}
          >
            {linkCopied ? "Link Copied!" : "Share this Destination"}
            {linkCopied && <FaCheck className="text-teal-500" />}
          </button>
        </form>
      </div>
    </div>
  );
}
