import CustomIcons from "../../../../constants/icons";
import { FaApple } from "react-icons/fa6";
import { DiAndroid } from "react-icons/di";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function AppPromoSection() {
  return (
    <div
      className="flex flex-col gap-x-20  md:flex-row px-10 md:py-32 items-center justify-center py-20 text-white"
      style={{
        backgroundImage: `url("images/app_promo_bg.png")`,
        backgroundSize: "cover",
        backgroundPosition: "right",
      }}
    >
      {/* image */}
      <div className="">
        <div className="relative h-96 w-96 md:scale-125 rounded-full">
          <LazyLoadImage
            src="images/app_promo.png"
            alt="Habibi Dubai"
            className="absolute inset-0 w-full h-full object-contain shrink-0"
          />
        </div>
      </div>

      {/* text */}
      <div className="mt-10 md:text-start md:w-1/3 ">
        <h2 className="text-3xl font-bold ">Albaca International Mobile App</h2>
        <div className="flex flex-col gap-2 md:flex-row justify-start items-start  md:items-center md:gap-5 mt-3">
          <p className="flex gap-1 items-center font-semibold">
            Available on IOS & Android
          </p>
        </div>
        <p className="mt-4 text-md">
          Discover the world with Albaca International Travel Agency.
          Personalized itineraries, exclusive destinations, seamless service.
          Every trip is unique and memorable. Your journey begins with us.
        </p>

        {/* buttons */}
        <div className="flex flex-col md:flex-row gap-5 lg:whitespace-nowrap flex-wrap xl:flex-nowrap  mt-9 justify-start items-stretch">
          {/* android app  */}
       <a href="https://play.google.com/store/apps/details?id=com.albaca.albaca_international&pli=1" target="_blank" className="flex justify-center bg-[#ffda32] hover:bg-transparent hover:text-white border-4 border-[#ffda32] text-black font-bold py-3 rounded-full px-10 transition-all duration-300 ease-in-out">
  <div className="flex gap-5 items-center">
    <DiAndroid size={25} />
    <span className="">Download For Android</span>
  </div>
</a>


          {/* ios app */}
          <button className="flex items-center justify-center   bg-[#ffda32] hover:bg-transparent hover:text-white border-4 border-[#ffda32] text-black font-bold py-3 rounded-full px-10 transition-all duration-300 ease-in-out">
            <div className="flex items-center gap-5 ">
              <FaApple size={25} />
              <span className="">Download For iOS</span>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
}
