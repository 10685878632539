import React from "react";
import CustomIcons from "../../../../constants/icons";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function TrendingPackage() {
  return (
    <div
      className="flex flex-col gap-x-20  md:flex-row px-10 md:py-20 items-center justify-center py-10 text-white"
      style={{
        backgroundImage: `url("images/dubai_bg.png")`,
        backgroundSize: "cover",
        backgroundPosition: "right",
      }}
    >
      {/* image */}
      <div className="">
        <div className="relative h-80 w-80  md:h-96 md:w-96 rounded-full">
          <LazyLoadImage
            src="images/dubai.svg"
            alt="Habibi Dubai"
            className="absolute inset-0 w-full h-full object-contain shrink-0"
          />
        </div>
      </div>

      {/* text */}
      <div className="mt-10 md:text-start md:w-1/3 ">
        <p className="bg-[#affff0] w-fit p-2 px-5 text-gray-900 mb-3 text-sm font-semibold rounded-full uppercase">
          Trending Now
        </p>
        <h2 className="text-3xl font-bold ">Habibi come to Dubai</h2>

        <div className="flex flex-col gap-2 md:flex-row justify-start items-start  md:items-center md:gap-5 mt-3">
          <p className="flex gap-1 items-center">
            {CustomIcons.location()} Burj Khalifa, UAE
          </p>

          <p>
            4.9{" "}
            {Array(5)
              .fill()
              .map((_, i) => (
                <span key={i} className="text-[#ffda32]">
                  ★
                </span>
              ))}{" "}
            (300 reviews)
          </p>
        </div>

        <p className="mt-4 text-md">
          The Burj Khalifa stands as a testament to human ingenuity, soaring
          above the Dubai skyline as the world's tallest building. Offering
          awe-inspiring views from its observation decks, this architectural
          marvel invites you to witness the cityscape like never before. Marvel
          at the sunset over the Arabian Gulf, dine in luxury at its high-rise
          restaurants, and experience the fusion of art and engineering in every
          corner. Explore the heights of innovation and luxury at the iconic
          Burj Khalifa.
        </p>

        {/* book now button */}
        <Link to={"/packages/ALB003"}>
          <button className="bg-[#ffda32] hover:bg-transparent hover:text-white border-4 border-[#ffda32] text-black font-bold py-3 rounded-full px-10 mt-6 transition-all duration-300 ease-in-out">
            Book Now
          </button>
        </Link>
      </div>
    </div>
  );
}
