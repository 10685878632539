import { useSelector } from "react-redux";

export default function Description() {
  const packageData = useSelector((state) => state.singlePackage.packageData);
  return (
    <div className="p-5 my-5 md:mt-0 md:py-0">
      <h2 className="text-2xl font-bold">Description</h2>
      <div className="text-gray-900  flex flex-col gap-5 mt-5 max-w-screen-md md:text-lg">
        {packageData.description.map((desc, index) => {
          return <p key={index}>{desc}</p>;
        })}
      </div>
    </div>
  );
}
