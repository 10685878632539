import { useDispatch, useSelector } from "react-redux";
import CustomIcons from "../../constants/icons";
import {
  googleSignInThunk,
  updateAuthScreenVisibleStatus,
} from "../../state/slices/auth/authSlice";

export default function AuthScreen(props) {
  const dispatch = useDispatch();
  const isAuthScreenVisible = useSelector(
    (state) => state.authSlice.isAuthScreenOpen
  );
  const imageLink =
    "https://images.unsplash.com/photo-1575986767340-5d17ae767ab0?q=80&w=1933&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";

  return (
    <div>
      {/* this is login options container */}
      {isAuthScreenVisible && (
        <div className="fixed flex justify-center items-center inset-0 h-[100vh] w-[100vw] z-[1000]">
          {/* // black overlay */}
          <div
            className="absolute bg-black bg-opacity-50  h-dvh w-dvw"
            // close the login options container when the bg black overlay is clicked
            onClick={() => dispatch(updateAuthScreenVisibleStatus(false))}
          ></div>
          {/* ============ ================ */}
          {/* login container */}
          <div className="relative bg-white w-[80%] z-10 h-fit flex flex-col items-center p-3 rounded-2xl md:p-0 lg:w-[60%]  md:flex-row md:justify-between max-w-screen-lg">
            {/* close button floating at top left */}
            <div
              className="absolute top-5 right-7 z-50 h-10 w-10 flex justify-center items-center  rounded-full shadow-md border font-bold bg-primary cursor-pointer hover:bg-red-600 hover:text-white transition-all duration-300 ease-in-out"
              onClick={() => dispatch(updateAuthScreenVisibleStatus(false))}
            >
              x
            </div>

            {/* conatiner 1 consists of only image */}
            {/* image */}
            <img
              src={imageLink}
              alt="login"
              className="w-full h-fit rounded-xl brightness-90 object-cover md:w-1/2 md:h-[30rem] md:shadow-xl md:rounded-tr-none md:rounded-br-none"
            />

            {/* container 2 */}

            {/* titles  */}
            <div className={`flex flex-col items-center my-9 w-full`}>
              {/* key icon */}
              <div className="bg-gray-100 p-3  md:p-5 rounded-full text-accent">
                {CustomIcons.key("md:scale-125")}
              </div>

              <div className="text-center mt-3 md:mt-7 ">
                <h3 className="font-semibold text-2xl md:text-5xl">Sign in</h3>
                <p className="md:text-xl md:mt-3">Lets start your journey.</p>
              </div>
              <div
                className="mt-7 md:mt-14
              "
              >
                {/* login options */}
                <button
                  className="flex gap-3 justify-center items-center border-2  px-7 py-3 rounded-full hover:border-secondary_light shadow-md hover:-translate-y-1 transition-all duration-300 ease-in-out flex-wrap "
                  onClick={() => {
                    dispatch(googleSignInThunk());
                  }}
                >
                  {CustomIcons.google()}
                  <p>Sign in with Google</p>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div>{props.children}</div>
    </div>
  );
}
