import { useSelector } from "react-redux";
import { Slide } from "react-slideshow-image";
import CustomIcons from "../../../constants/icons";
import { useState, useRef } from "react";
import "react-dropdown/style.css";
import BookFrom from "./book_form";
import Advantages from "./advantages";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function ImageGallery() {
  const packageData = useSelector((state) => state.singlePackage.packageData);

  const [selectedImageIndex, setSelectedImage] = useState(0);

  // a function to handle the click event on the thumbnail images
  const slideRef = useRef(null);
  const handleThumbnailClick = (index) => {
    setSelectedImage(index);
    slideRef.current.goTo(index);
  };

  return (
    <div className="  flex flex-col justify-evenly lg:flex-row lg:mt-12 ">
      {/* container 1 */}
      <div className="lg:w-[70%]">
        {/* main image */}
        <Slide
          ref={slideRef}
          cssClass={`bg-black user-select-none o max-h-[25rem] aspect-video`}
          autoplay={true}
          infinite
          pauseOnHover={false}
          easing="ease-in"
          defaultIndex={selectedImageIndex}
          transitionDuration={500}
          prevArrow={CustomIcons.arrowLeft(
            "text-black absolute top-1/2 left-0 ml-5 bg-white rounded-full p-1.5 hover:scale-110 transition-all duration-200 ease-in-out backface-visibility-hidden",
            37
          )}
          nextArrow={CustomIcons.arrowRight(
            "text-black absolute top-1/2 right-0 mr-5 bg-white rounded-full p-1.5 hover:scale-110 transition-all duration-200 ease-in-out backface-visibility-hidden",
            37
          )}
          onChange={(oldIndex, newIndex) => setSelectedImage(newIndex)}
        >
          {packageData.gallery.map((imageLink, index) => (
            <LazyLoadImage
              key={index}
              src={imageLink}
              alt="gallery_image"
              className="h-full object-cover aspect-auto max-h-[25rem] w-full"
            />
          ))}
        </Slide>
        {/* gallery */}
        <div className="grid grid-cols-3 pt-5 p-3 md:px-0 gap-2 md:grid-cols-6">
          {packageData.gallery.map((imageLink, index) => (
            <LazyLoadImage
              key={index}
              onClick={() => handleThumbnailClick(index)}
              src={imageLink}
              alt="gallery_image"
              className={`w-full  aspect-square md:aspect-video object-cover rounded-xl border-[3px] transition-all duration-300 ease-in-out cursor-pointer ${
                selectedImageIndex === index
                  ? "border-secondary_light shadow-md"
                  : "border-transparent brightness-75"
              }`}
            />
          ))}
        </div>
        {/* show this in flex-row so that its just below the gallery in larger screens*/}
        <div className="hidden lg:block">
          <Advantages />
        </div>
      </div>

      {/* container 2 */}
      <BookFrom />

      {/* show this in smaller screens cause everything is in col so that it comes below the form */}
      <div className="lg:hidden">
        <Advantages />
      </div>
    </div>
  );
}
