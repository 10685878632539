import { Slide } from "react-slideshow-image";
import CustomIcons from "../../../../../constants/icons";
import Utils from "../../../../../utils/utils";
import { Link, useNavigate } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function DesktopSlider({
  sliderImagesList,
  destination,

  setDestination,
}) {
  const hoverColor = "#f3f3f3";

  const navigate = useNavigate();

  const divider = () => <div className="w-1 bg-gray-300 h-auto my-3"></div>;
  const height = "40rem";

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  return (
    <div
      className={`relative hidden min-[950px]:block overflow-hidden w-full bg-red-500 user-select-none`}
    >
      {/* =========== */}
      {/* ============= */}
      {/* Form parent container floating */}
      <div className="absolute bottom-9 z-20 flex flex-col justify-center items-center w-full">
        {/* form container  */}
        <div className="flex flex-col items-start">
          <h3 className="text-white font-bold text-xl ml-10 mb-2">Holidays</h3>

          {/* bar  */}
          <div className="bg-white px-9 pr-5 rounded-full w-full">
            <form className="flex">
              {/* =========== */}
              {/* location search input*/}
              <div
                className={`py-3 flex items-center gap-5 cursor-pointer  w-[30rem]`}
              >
                {CustomIcons.location("mr-1")}
                <div className="flex gap-1 flex-col">
                  <lable>Enter your dream destination</lable>
                  <input
                    className="border-none w-[25rem] focus:border-none focus:outline-none text-xl font-semibold"
                    type="text"
                    placeholder="Search.."
                    onKeyDown={handleKeyDown}
                    onChange={(e) => setDestination(e.target.value)}
                    name={destination}
                  />
                </div>
              </div>

              {/* divider */}
              {divider()}
              {/*  */}

              {/* TODO: implement in future */}

              {/* month */}
              {/* <div
                className={`flex items-center gap-x-5 hover:bg-[${hoverColor}] px-9 cursor-default`}
              >
                {CustomIcons.calendar("mr-1")}
                <div className="flex gap-0 flex-col">
                  <lable>Month</lable>
                  <p className="font-bold text-xl">{Utils.getMonth()}</p>
                </div>
              </div> */}

              {/* divider */}
              {/* {divider()} */}
              {/*  */}

              {/* package type */}
              {/* <div
                className={`flex items-center gap-5 pl-9 pr-2 hover:hover:bg-[${hoverColor}] cursor-default`}
              >
                <div className="flex  flex-col">
                  <lable>Stay Duration</lable>
                  <p className="font-bold text-xl">{packages}</p>
                </div>
                {CustomIcons.arrowDown("mr-1")}
              </div> */}

              {/* search button */}
              <Link
                to={`/packages?q=${destination}`} // search query
                className="bg-secondary text-white p-0 h-fit self-center px-9 ml-9 py-4 rounded-full my-1"
              >
                Search
              </Link>
            </form>
          </div>
        </div>
      </div>

      {/* ======= SLIDER =========== */}
      <Slide
        // dynamic height variable is not supporting and breaking the slider
        cssClass={`h-[40rem] w-full bg-black user-select-none w-full bg-black user-select-none   object-cover`}
        autoplay={true}
        infinite
        pauseOnHover={false}
        easing="ease-in"
        onChange={(oldIndex, newIndex) => console.log(`slide transition`)}
        transitionDuration={500}
        prevArrow={CustomIcons.arrowLeft(
          "text-black absolute top-1/2 left-0 ml-9 bg-white rounded-full p-1.5 hover:scale-110 transition-all duration-200 ease-in-out backface-visibility-hidden",
          37
        )}
        nextArrow={CustomIcons.arrowRight(
          "text-black absolute top-1/2 right-0 mr-9 bg-white rounded-full p-1.5 hover:scale-110 transition-all duration-200 ease-in-out backface-visibility-hidden",
          37
        )}
      >
        {sliderImagesList.map((image, index) => (
          <div key={index} className="relative overflow-hidden">
            <div>
              {/* redering accroding to the link type */}

              {/* if image return the image tag */}
              {image.type === "image" ? (
                <LazyLoadImage
                  src={image.src}
                  alt={image.alt}
                  className={`bg-black user-select-none filter brightness-75 w-full object-cover h-[${height}]`}
                />
              ) : (
                // if video return the video tag
                <video
                  src={image.src}
                  alt={image.alt}
                  className={`bg-black user-select-none filter brightness-75 w-full object-cover h-[${height}]`}
                  autoPlay={true}
                  loop
                  muted
                ></video>
              )}
            </div>
            {/* ======================= */}
            {/* text upon image */}
            <div className="absolute inset-0 top-40 z-10 left-[17%] flex text-white flex-col items-start w-3/4">
              <div className="flex flex-col items-start">
                <p
                  className=" text-3xl"
                  dangerouslySetInnerHTML={{
                    __html: image.info.subtitle,
                  }}
                ></p>
                <h1
                  className=" font-bold text-5xl mt-4"
                  dangerouslySetInnerHTML={{
                    __html: image.info.title,
                  }}
                ></h1>

                {/* button on Hero Image */}
                <div className="flex items-baseline gap-5">
                  {/* dont show the button for index 4 & 1 */}
                  {index !== 4 && index !== 3 && (
                    <button
                      onClick={() => {
                        // if its a link redirect
                        const redirect = image.info.redirect;
                        if (redirect.includes("/")) {
                          navigate(image.info.redirect);
                        }
                        // if its ID then scroll to it
                        else {
                          const targetElement =
                            document.getElementById(redirect);
                          if (targetElement) {
                            targetElement.scrollIntoView({
                              behavior: "smooth",
                              block: "start",
                            });
                          }
                        }
                      }}
                      className="text-black bg-white px-5 py-2.5 mt-9 rounded-full font-semibold capitalize hover:bg-secondary hover:text-primary border-2 transition-colors duration-300 ease-in-out"
                    >
                      {image.info.button_name.split("_").join(" ")}
                    </button>
                  )}
                  {/* coupon code */}
                  <p>
                    {image.info.coupon ? `Code : ${image.info.coupon}` : ""}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slide>
    </div>
  );
}
