import { useDispatch, useSelector } from "react-redux";
import AdminDashboard from "./admin_dashboard";
import { updateAuthScreenVisibleStatus } from "../state/slices/auth/authSlice";
import LoadingScreen from "../components/handling_screens/loading_screen";

export default function AdminAuthenticator() {
  const user = useSelector((state) => state.authSlice.user);

  const userLoading = useSelector((state) => state.authSlice.loading);

  let adminEmail = process.env.REACT_APP_ADMIN_EMAIL;

  const dispatch = useDispatch();

  if (user != null) {
    if (
      user.email === adminEmail
    ) {
      return (
        <div>
          <AdminDashboard />;
        </div>
      );
    } else {
      return <Unauthorized />;
    }
  }

  if (userLoading) {
    return <LoadingScreen />;
  }

  if (user == null && !userLoading) {
    dispatch(updateAuthScreenVisibleStatus(true));

    return <Unauthorized />;
  }

  // return <AdminDashboard />;
}

const Unauthorized = () => {
  return (
    <h1 className="flex h-screen justify-center items-center text-center font-bold text-red-500 text-5xl">
      🚫 Unauthorized
    </h1>
  );
};
