import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchJobIdsThunk,
  handlePackageFormVisibility,
  deleteJobThunk,
  handleCareerFormVisibility,
} from "../../../state/slices/admin/admin_slice";
import CareerCustomForm from "./career_custom_form";
import { FaUserClock } from "react-icons/fa";
import { GrLocation } from "react-icons/gr";

export default function CareerAdminScreen() {
  const dispatch = useDispatch();

  const isFormOpen = useSelector((state) => state.adminSlice.isCareerFormOpen);

  const allJobsData = useSelector((state) => state.adminSlice.allJobsData);

  const [currentEditingIndex, setCurrentEditingIndex] = useState(null);

  // getting all pacakge ids and name here here
  useEffect(() => {
    dispatch(fetchJobIdsThunk());
  }, [dispatch]);

  // handleEdit
  const handleEdit = (index) => {
    setCurrentEditingIndex(index);
    dispatch(handleCareerFormVisibility(true));
  };

  const handleDelete = (jobId) => {
    dispatch(deleteJobThunk(jobId));
  };

  return (
    <div className="max-w-screen-xl mx-auto p-5">
      {/* custom form */}
      {isFormOpen && (
        <CareerCustomForm
          // if the intital data is an empty object then the form wont be prefilled
          initialData={
            currentEditingIndex !== null
              ? allJobsData[currentEditingIndex]
              : {
                  id: "",
                  title: "",
                  company: "",
                  location: "",
                  type: "",
                  salary: "",
                  logo: "",
                  description: "",
                }
          }
        />
      )}

      {/* jobs  */}

      <main className="py-10">
        <div>
          {/*  */}
          <div className="flex justify-between">
            <h2 className="font-bold text-lg bg-secondary text-white w-fit px-3 py-1 rounded-lg">
              All Jobs
            </h2>

            <button
              className="border px-5 bg-green-500 text-white rounded-lg"
              onClick={() => {
                setCurrentEditingIndex(null);
                dispatch(handleCareerFormVisibility(true));
              }}
            >
              + Add
            </button>
          </div>

          <div className="pt-10 grid lg:grid-cols-2 gap-4">
            {
              allJobsData.map((job, index) => (
                <JobCard
                  key={job.id}
                  job={job}
                  handleDelete={handleDelete}
                  handleEdit={handleEdit}
                  index={index}
                />
              ))
              // Display the list of package ids here
            }
          </div>
        </div>
      </main>
    </div>
  );
}

const JobCard = ({ job, handleEdit, index, handleDelete }) => {
  return (
    <div className="flex-1 flex">
      <div className="relative border bg-white rounded-lg shadow-md p-6 flex gap-x-5 items-start flex-grow">
        {/* Company logo */}
        <img
          src={job.logo}
          alt="Company Logo"
          className="w-16 h-16 rounded-md object-cover shrink-0"
        />

        <div className="flex flex-col flex-grow">
          <div className="flex flex-col items-start justify-between w-full">
            <h2 className="text-md">
              <span className="font-semibold">ID:</span> {job.id}
            </h2>
            <h2 className="text-xl font-semibold">{job.title}</h2>
          </div>
          <p className="text-gray-500 text-sm">{job.company}</p>
          <div className="flex flex-wrap items-center text-gray-500 text-sm mt-2 gap-x-5">
            <span className="flex items-center space-x-1">
              <GrLocation />
              <span>{job.location}</span>
            </span>
            <span className="flex items-center space-x-1">
              <FaUserClock />
              <span>{job.type}</span>
            </span>
            <span className="flex items-center space-x-1">
              <i className="fas fa-dollar-sign"></i>
              <span>{job.salary}</span>
            </span>
            <span className="flex items-center space-x-1">
              <span>{job.posted}</span>
            </span>
          </div>
          <p className="text-gray-500 text-sm mt-4">{job.description}</p>

          {/* buttons */}
          <div className="mt-5 grid grid-cols-2 items-start gap-3 w-fit">
            {/* EDIT button */}
            <button
              className="border border-secondary px-3 py-1 rounded-lg"
              onClick={() => handleEdit(index)}
            >
              edit
            </button>

            {/* Delete Button */}
            <button
              className="bg-red-500 text-white px-3 py-1 rounded-lg"
              onClick={() => {
                handleDelete(job.id);
              }}
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
