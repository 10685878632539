import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  addNewJobThunk,
  handleCareerFormVisibility,
} from "../../../state/slices/admin/admin_slice";

const CareerCustomForm = ({ initialData }) => {
  const dispatch = useDispatch();
  const [jobDetails, setJobDetails] = useState(initialData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setJobDetails({
      ...jobDetails,
      [name]: value,
    });
  };

  // HANDLE SUBMIT
  const handleSubmit = (e) => {
    e.preventDefault();

    // Add form submission logic here

    // alert(JSON.stringify(jobDetails));

    dispatch(addNewJobThunk(jobDetails));
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center p-3 md:p-10">
      <div
        className="fixed inset-0 bg-black bg-opacity-35"
        onClick={() => dispatch(handleCareerFormVisibility(false))}
      ></div>
      <form
        onSubmit={handleSubmit}
        className="relative z-10 w-full max-w-lg p-8 bg-white h-[90vh]
        overflow-y-auto  rounded-lg shadow-lg space-y-6"
      >
        <h2 className="text-2xl font-semibold text-gray-800">Career Form</h2>
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              id
            </label>
            <input
              type="text"
              name="id"
              value={jobDetails.id}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md p-3 border-gray-300 shadow-sm focus:border-indigo-500 border focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Title
            </label>
            <input
              type="text"
              name="title"
              value={jobDetails.title}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md p-3 border-gray-300 shadow-sm focus:border-indigo-500 border focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Company
            </label>
            <input
              type="text"
              name="company"
              value={jobDetails.company}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm p-3 focus:border-indigo-500 border focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Logo Link
            </label>
            <input
              type="text"
              name="logo"
              value={jobDetails.logo}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm p-3 focus:border-indigo-500 border focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Location
            </label>
            <input
              type="text"
              name="location"
              value={jobDetails.location}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm p-3 focus:border-indigo-500 border focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Type
            </label>
            <input
              type="text"
              name="type"
              value={jobDetails.type}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm p-3 focus:border-indigo-500 border  focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Salary
            </label>
            <input
              type="text"
              name="salary"
              value={jobDetails.salary}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm p-3 focus:border-indigo-500 border focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Description
            </label>
            <textarea
              name="description"
              rows="5"
              value={jobDetails.description}
              onChange={handleChange}
              className="mt-1 px-5 pt-2 block w-full rounded-md border-gray-300 shadow-sm  focus:border-indigo-500 border focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>
        </div>
        <div className="flex justify-end space-x-4">
          <button
            type="button"
            onClick={() => dispatch(handleCareerFormVisibility(false))}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default CareerCustomForm;
