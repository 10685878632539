import React, { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

const data = [
  {
    title: "Extensive Hotel Selection",
    description:
      "We bring you a wide array of options to book hotels in India and international destinations. Explore a diverse range of accommodations, from luxurious 5-star hotels to cozy budget hotels, all with just a few clicks.",
  },
  {
    title: "Convenience at Your Fingertips",
    description:
      "Our platform allows you to conveniently book hotels online from the comfort of your home or while on the go. No more searching through countless websites or making phone calls - everything you need is just a few clicks away.",
  },
  {
    title: "Best Hotel Booking Site",
    description:
      "Albaca Internationals Platform is renowned as one of the best hotels booking sites, offering competitive rates and exclusive deals. Our partnerships with leading hotel chains ensure that you receive the best value for your money.",
  },
  {
    title: "Easy Comparison and Selection",
    description:
      "With our intuitive search filters, you can effortlessly compare different hotels based on price, location, amenities, and guest reviews. This enables you to make an informed decision and find the perfect accommodation that suits your preferences.",
  },
  {
    title: "Booking Flexibility",
    description:
      "Our platform offers flexible options for hotel room booking. Whether you need a last-minute reservation or prefer to plan-in-advance, we have you covered. You can easily modify or cancel your booking as per your convenience, subject to the hotel's cancellation policy.",
  },
  {
    title: "Local and International Expertise",
    description:
      "Whether you are traveling within India or venturing abroad, we have a vast selection of hotels, including international hotels, to cater to your needs. Discover a wide variety of options, ranging from luxurious to cheap hotels, for you to choose from. Benefit from our expertise in both domestic and international markets, ensuring a memorable stay wherever your journey takes you.",
  },
];

const whyAlbacaInternationalsData = [
  {
    title: "Competitive Pricing",
    description:
      "We understand the importance of finding the best hotel deals, which is why we strive to offer competitive pricing. Benefit from exclusive discounts and special promotions, ensuring that you get the most value for your money.",
  },
  {
    title: "Enhanced User Experience",
    description:
      "We are committed to providing an enhanced user experience. Our platform is designed to be user-friendly, making it easy for you to find and book the perfect hotel for your needs.",
  },
  {
    title: "Trusted and Secure Transactions",
    description:
      "We prioritize the security of your personal and financial information. Albaca Internationals platform follows industry-standard security protocols, providing you with a safe and secure environment for all your transactions.",
  },
  {
    title: "Reliable Customer Support",
    description:
      "Our dedicated customer support team is always ready to assist you with any queries or concerns. We aim to provide reliable and timely support to ensure a hassle-free booking experience.",
  },
];

const TextSection = () => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className="md:py-14 md:pb-0">
      <div className="max-w-screen-2xl mx-auto text-left px-4">
        <div
          className={`${
            expanded ? "line-clamp-none" : "line-clamp-6"
          } transition-all duration-200 ease-in-out`}
        >
          <h2 className="text-2xl font-semibold mt-8 mb-4">
            Albaca Int Hotel Services
          </h2>
          <ul className="list-disc pl-8">
            {data.map((item, index) => (
              <li key={index} className="mt-5">
                <h2 className="text-xl font-bold mb-2">{item.title}</h2>
                <p className="text-gray-600">{item.description}</p>
              </li>
            ))}
          </ul>
          <h2 className="text-2xl font-semibold mt-8 mb-4">
            Why Albaca Internationals?
          </h2>
          <ul className="list-disc pl-8">
            {whyAlbacaInternationalsData.map((item, index) => (
              <li key={index} className="mt-5">
                <h3 className="text-xl font-bold mb-2">{item.title}</h3>
                <p className="text-gray-600">{item.description}</p>
              </li>
            ))}
          </ul>
        </div>
        <button
          onClick={() => {
            setExpanded(!expanded);
          }}
          className="mt-5 px-4 pl-9 py-2 text-secondary_light underline rounded hover:no-underline"
        >
          {expanded ? "Read Less" : "Read More"}
        </button>
      </div>

      <LazyLoadImage
        className="pt-5 h-96 w-full object-cover shrink-0"
        src="https://images.unsplash.com/photo-1621293954908-907159247fc8?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        alt="hotel"
      />
    </div>
  );
};

export default TextSection;
