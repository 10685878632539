import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../firebase_config/firebase";

import Utils from "../../../utils/utils";

export const getPackageDataThunk = createAsyncThunk(
  "singlePackage/getPackageData",
  async (package_id, { rejectWithValue }) => {
    try {
      const docRef = doc(db, "packages", package_id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        return docSnap.data();
      } else {
        return rejectWithValue(404);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  packageData: null,
  error: null,
};

const singlePackageSlice = createSlice({
  name: "singlePackage",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPackageDataThunk.fulfilled, (state, action) => {
      console.log(JSON.stringify(action.payload));
      state.error = null;
      state.packageData = action.payload;
    });

    builder.addCase(getPackageDataThunk.pending, (state, action) => {
      state.error = null;
      state.packageData = null;
    });

    builder.addCase(getPackageDataThunk.rejected, (state, action) => {
      console.log(action.payload);
      if (action.payload === 404) {
        state.error = "404 Not Found!";
      } else {
        state.error = "Something went wrong! Please try again later.";
      }
    });
  },
});

export const {} = singlePackageSlice.actions;
export default singlePackageSlice.reducer;
