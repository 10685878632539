import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./components/home_page/home_page";
import { Provider } from "react-redux";
import store from "./store/store";
import AuthValidatorWrapper from "./auth/auth_validator_wrapper";
import SinglePackageScreen from "./components/single_package/single_package";
import Navbar from "./components/navbar/nav_bar";
import AllPackagesPage from "./components/all_packages/all_packages";
import ContactUsScreen from "./components/info_screens/contact_us_screen/contact_us_screen";
import AboutUsScreen from "./components/info_screens/about/about_screen";
import AdminAuthenticator from "./admin/admin_authenticator";
import CareerScreen from "./components/career/career";
import Policies from "./components/info_screens/legal/privacy_policy";
import TermsAndConditions from "./components/info_screens/legal/terms_and_condition";
import Footer from "./components/footer/footer";
import ScrollToTop from "./utils/scroll_to_top";
import UnderMintenanceComponent from "./components/under_maintenance/under_maintenance";

function App() {
  if (process.env.NODE_ENV !== "development") console.log = () => {};

  // return <UnderMintenanceComponent />;

  return (
    <Provider store={store}>
      <AuthValidatorWrapper>
        <div className="relative">
          {/* resposive tags stick at the top*/}
          {/* <ResponsiveTags /> */}
          <Router>
            <ScrollToTop />
            <Navbar>
              {/* this is to make sure the content is not expanded past 2xl screens */}
              <div className="relative max-w-screen-2xl m-auto">
                <Routes>
                  <Route path="/" element={<HomePage />} />
                  <Route
                    path="/packages/:id"
                    element={<SinglePackageScreen />}
                  />
                  <Route path="/packages" element={<AllPackagesPage />} />
                  <Route path="/contact-us" element={<ContactUsScreen />} />
                  <Route path="/about-us" element={<AboutUsScreen />} />
                  <Route path="/career" element={<CareerScreen />} />
                  <Route path="/admin" element={<AdminAuthenticator />} />
                  <Route path="/privacy-policy" element={<Policies />} />
                  <Route
                    path="/terms-and-condition"
                    element={<TermsAndConditions />}
                  />
                </Routes>
              </div>
              <Footer />
            </Navbar>
          </Router>
        </div>
      </AuthValidatorWrapper>
    </Provider>
  );
}

export default App;
