import DetailedItinerary from "./sections/itinerary";
import ImageGallery from "./sections/image_gallery";
import Description from "./sections/description";

import { useParams } from "react-router-dom";
import PackageHeader from "./sections/package_header";
import { useDispatch, useSelector } from "react-redux";
import LoadingScreen from "../handling_screens/loading_screen";
import { useEffect } from "react";
import { getPackageDataThunk } from "../../state/slices/single_package/single_package_slice";
import ErrorScreen from "../handling_screens/error_screen";

export default function SinglePackageScreen() {
  const { id } = useParams();
  const dispatch = useDispatch();

  // Scroll to top when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const packageData = useSelector((state) => state.singlePackage.packageData);
  const error = useSelector((state) => state.singlePackage.error);

  useEffect(() => {
    dispatch(getPackageDataThunk(id));
  }, [dispatch, id]);

  if (error != null) {
    return <ErrorScreen error={error} />;
  }

  if (packageData != null) {
    return (
      <div className="max-w-screen-xl m-auto pb-9 md:px-9">
        {/* <h1 className="text-5xl">{id}</h1> */}
        {/* package header info */}
        <PackageHeader />
        {/* top image gallery */}
        <ImageGallery />

        {/* detailed itinerary */}
        <DetailedItinerary />

        {/* description */}
        <Description />
      </div>
    );
  }

  return <LoadingScreen />;
}
