import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../../firebase_config/firebase";

export const fetchPackagesIdsThunk = createAsyncThunk(
  "adminSlice/fetchPackagesIds",
  async (_, thunkAPI) => {
    try {
      const querySnapshot = await getDocs(collection(db, "packages"));
      const packagesIdAndName = querySnapshot.docs.map((doc) => doc.data());

      return packagesIdAndName;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// add new package
export const addNewPackageThunk = createAsyncThunk(
  "adminSlice/addNewPackage",
  async (newPackageData, thunkAPI) => {
    try {
      const docRef = doc(db, "packages", newPackageData.package_id);
      await setDoc(docRef, newPackageData);

      return newPackageData;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// delete package
export const deletePackageThunk = createAsyncThunk(
  "adminSlice/deletePackage",
  async (packageId, thunkAPI) => {
    try {
      await deleteDoc(doc(db, "packages", packageId));
      return packageId;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// =========================== JOBS ================================
// ===========================================================
// ===========================================================
// ===========================================================

// fetch all jobs
export const fetchJobIdsThunk = createAsyncThunk(
  "adminSlice/fetchJobIds",
  async (_, thunkAPI) => {
    try {
      const querySnapshot = await getDocs(collection(db, "jobs"));
      const jobIdAndName = querySnapshot.docs.map((doc) => doc.data());

      return jobIdAndName;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// add new job
export const addNewJobThunk = createAsyncThunk(
  "adminSlice/addNewJob",
  async (newJobData, thunkAPI) => {
    try {
      const docRef = doc(db, "jobs", newJobData.id);
      await setDoc(docRef, newJobData);

      return newJobData;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// delete job
export const deleteJobThunk = createAsyncThunk(
  "adminSlice/deleteJob",
  async (jobId, thunkAPI) => {
    try {
      await deleteDoc(doc(db, "jobs", jobId));
      return jobId;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const initState = {
  allPackagesData: [],
  allJobsData: [],
  isPackageFormOpen: false,
  isCareerFormOpen: false,
};

const AdminSlice = createSlice({
  name: "adminSlice",
  initialState: initState,
  reducers: {
    handlePackageFormVisibility(state, action) {
      state.isPackageFormOpen = action.payload;
    },
    handleCareerFormVisibility(state, action) {
      state.isCareerFormOpen = action.payload;
    },
  },
  extraReducers: (builder) => {
    // fetch all packages
    builder
      .addCase(fetchPackagesIdsThunk.fulfilled, (state, action) => {
        state.allPackagesData = action.payload;
      })
      .addCase(addNewPackageThunk.fulfilled, (state, action) => {
        state.isPackageFormOpen = false;
        const index = state.allPackagesData.findIndex(
          (pkg) => pkg.package_id === action.payload.package_id
        );
        if (index !== -1) {
          // Update existing package
          state.allPackagesData[index] = action.payload;
        } else {
          // Add new package
          state.allPackagesData.unshift(action.payload);
        }

        alert("Package Added Successfully");
      })
      .addCase(addNewPackageThunk.rejected, (state, action) => {
        alert(JSON.stringify(action.error.message));
      })
      .addCase(deletePackageThunk.fulfilled, (state, action) => {
        state.allPackagesData = state.allPackagesData.filter(
          (pkg) => pkg.package_id !== action.payload
        );
        alert("Delete Successfully");
      })
      .addCase(deletePackageThunk.rejected, (state, action) => {
        alert(JSON.stringify(action.error.message));
      });

    // ==================================================================
    // ==================================================================
    // ====================       JOBS     ==============================
    // ==================================================================
    // ==================================================================
    builder
      .addCase(fetchJobIdsThunk.fulfilled, (state, action) => {
        state.allJobsData = action.payload;
      })
      .addCase(addNewJobThunk.fulfilled, (state, action) => {
        state.isCareerFormOpen = false;

        // checking if the job already exists
        const index = state.allJobsData.findIndex(
          (job) => job.id === action.payload.id
        );

        // if it exist update the its data
        if (index !== -1) {
          // Update existing job
          state.allJobsData[index] = action.payload;
        } else {
          // Add new job if job doesnt exist
          state.allJobsData.push(action.payload);
        }

        alert("Job Added Successfully");
      })
      .addCase(addNewJobThunk.rejected, (state, action) => {
        alert(JSON.stringify(action.error.message));
      })
      .addCase(deleteJobThunk.fulfilled, (state, action) => {
        state.allJobsData = state.allJobsData.filter(
          (job) => job.id !== action.payload
        );
        alert("Deleted Successfully");
      })
      .addCase(deleteJobThunk.rejected, (state, action) => {
        alert(JSON.stringify(action.error.message));
      });
  },
});

export const { handlePackageFormVisibility, handleCareerFormVisibility } =
  AdminSlice.actions;
export default AdminSlice.reducer;
