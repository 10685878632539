// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAhpdg-QI73NLlw9z6FenlS_eEWi2UzvVA",
  authDomain: "albaca-f8457.firebaseapp.com",
  projectId: "albaca-f8457",
  storageBucket: "albaca-f8457.appspot.com",
  messagingSenderId: "780588435362",
  appId: "1:780588435362:web:b225f1b3eb3a6e84e394fb",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

// Initialize Auth
const auth = getAuth(app);

// Initialize Storage
const storage = getStorage(app);

// Export the initialized instances
export { db, auth, storage };

export const initFirebase = () => app;
