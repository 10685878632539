import { FaCheck } from "react-icons/fa";
import { useSelector } from "react-redux";

export default function Itinerary() {
  const packageData = useSelector((state) => state.singlePackage.packageData);

  const itinerary = packageData.itinerary;

  return (
    <div className=" p-5 md:py-24 ">
      {/* title */}
      <h2 className="text-xl font-bold mb-5 text-[#3169aa]">Your Itinerary</h2>
      {/* destination name */}
      <p className="text-md font-semibold">{packageData.package_type}</p>
      <h2 className="text-2xl font-semibold">{packageData.destination}</h2>

      {/* deatiled itinerary */}
      <div
        className=" mx-5 p-5 py-0  mt-7 flex flex-col gap-7 "
        style={{
          borderLeft: "2.5px solid #e5a0c3",
        }}
      >
        {itinerary.map((day, index) => {
          return (
            <div key={index} className="relative flex flex-col gap-2">
              <h3 className="font-bold">Day {day.day}</h3>
              <ul className="flex flex-col gap-3 pt-3">
                {day.description.map((item, index) => {
                  return (
                    <li key={index} className="flex items-center gap-4">
                      {/* <div className="flex-shrink-0 h-3 w-3 rounded-full bg-gray-200"></div> */}
                      <div className="w-5 h-5 shrink-0  rounded-full overflow-hidden">
                        <img src={item.iconLink} alt="" />
                      </div>
                      <div>{item.info}</div>
                    </li>
                  );
                })}
              </ul>

              {/* round dots next to day */}
              <div className="absolute w-5 h-5 rounded-full bg-[linear-gradient(to_right,#ad3d77,#6a275d)] -left-[1.95rem]"></div>

              {/* flat bar at the last day to denote that the trip has finished */}
              {index === itinerary.length - 1 && (
                <div className="absolute w-5 h-0.5 bottom-0 bg-[#e5a0c3] -left-[1.95rem]"></div>
              )}
            </div>
          );
        })}
      </div>
      {/* over view */}
      <div className="mt-20">
        <p className="mb-5 font-bold text-2xl">Overview</p>
        <div className="px-5 border border-gray-200 shadow-md rounded-xl py-7 flex flex-col gap-16 bg-[#f7f7f7]">
          {itinerary.map((day, index) => {
            return (
              <div
                key={index}
                className="relative flex flex-col gap-2 text-xl  md:items-baseline"
              >
                <h3 className="font-bold px-5 whitespace-nowrap ">
                  Day {day.day}
                </h3>
                <ul className="grid grid-cols-1 md:grid-cols-3 gap-2 md:gap-y-9  pt-3 md:border-b-2 md:pb-9  ">
                  {day.description.map((item, index) => {
                    return (
                      <li
                        key={index}
                        className=" flex flex-col items-baseline gap-3 border-b-2 md:border-r-2 border-gray-200 p-5 shadow-sm bg-primary"
                      >
                        <img
                          src={item.imageLink}
                          alt="itinerary_image"
                          className="w-15 h-15 aspect-video object-cover rounded-lg flex-shrink-"
                        />

                        <p className="text-base py-1">{item.info}</p>
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
        </div>
      </div>

      {/* inclusions container*/}
      <div className="mt-14 md:mt-24 flex flex-col md:flex-row gap-5 ">
        <div className="bg-teal-100 p-3 py-5 md:p-7 rounded-lg w-full">
          <p className="font-semibold text-xl py-1">Included</p>
          {/* inclusions */}
          <div className="mt-3 flex flex-col gap-3 px-2">
            {packageData.inclusions.map((item, index) => {
              return (
                <div key={index} className="flex items-baseline gap-4">
                  <div className="w-3 h-3 items-center shrink-0 bg-teal-500 rounded-full"></div>
                  <div>{item}</div>
                </div>
              );
            })}
          </div>
        </div>
        {/* exclusions */}
        <div className="bg-red-100 p-3 py-5 md:p-7 rounded-lg  w-full">
          <p className="font-semibold text-xl py-1">Excluded</p>
          {/* inclusions */}
          <div className="mt-3 flex flex-col gap-3 px-2">
            {packageData.exclusions.map((item, index) => {
              return (
                <div key={index} className="flex items-baseline gap-4">
                  <div className="w-3 h-3 items-center shrink-0 bg-red-400 rounded-full"></div>
                  <div>{item}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
