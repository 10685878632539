import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPackageDataThunk } from "../../../../state/slices/single_package/single_package_slice";
import { Slide } from "react-slideshow-image";
import {
  FaBus,
  FaTree,
  FaCar,
  FaBriefcase,
  FaMapMarkerAlt,
} from "react-icons/fa";
import { getExploreCitypackageData } from "../../../../state/slices/home_page/home_page_slice";
import LoadingScreen from "../../../handling_screens/loading_screen";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function ExplorePopularCities() {
  const dispatch = useDispatch();

  const [selectedIndex, setSelectedIndex] = useState(0);

  const packageData = useSelector(
    (state) => state.homePageSlice.exploreCitypackageData
  );

  const cities = [
    {
      name: "Goa",
      id: "ALB004",
    },
    {
      name: "Munnar",
      id: "ALB001",
    },
    {
      name: "Kodaikanal",
      id: "ALB005",
    },
    {
      name: "Thailand",
      id: "ALB006",
    },
    {
      name: "Dubai",
      id: "ALB003",
    },
    {
      name: "Bali",
      id: "ALB007",
    },
  ];

  const servicesList = [
    {
      title: "Public Transportations",
      icon: <FaBus />,
      color: "#d176e0",
    },
    {
      title: "Nature and Adventure",
      icon: <FaTree />,
      color: "#7bbcb0",
    },
    {
      title: "Private Transportations",
      icon: <FaCar />,
      color: "#e4b613",
    },
    {
      title: "Business Tours",
      icon: <FaBriefcase />,
      color: "#fc3131",
    },
    {
      title: "Local Visit",
      icon: <FaMapMarkerAlt />,
      color: "#5c9bde",
    },
  ];

  //   this is resposible to hold the dynaimc height of the floating container
  const floatingContainer = useRef(null);
  const [floatingContentHeight, setAaHeight] = useState(0);

  //   this is responsible to set the dynamic height of the floating container
  useEffect(() => {
    const handleResize = () => {
      if (floatingContainer.current === null) return;
      setAaHeight(floatingContainer.current.clientHeight);
    };

    // Invoke the resize handler function once when the component mounts
    handleResize();

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener when component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [packageData]);

  // fetching data here
  useEffect(() => {
    // fetch data
    dispatch(getExploreCitypackageData(cities[selectedIndex].id));
  });

  return (
    <div className="mt-16 mx-auto flex flex-col justify-center items-center">
      <div className="px-5">
        {/* title */}
        <h2 className="text-2xl md:text-3xl font-bold text-center">
          Explore Popular Cities
        </h2>

        {/* subtitle */}
        <p className="mt-3 text-center text-gray-700 max-w-xl md:text-lg">
          Explore Top Cities: Your Ultimate Tour Package Hub. Unforgettable
          adventures, tailored itineraries. Start Exploring Now
        </p>
      </div>
      {/* cities */}
      <div className="mt-11  flex flex-col justify-center items-center">
        <div
          className={`pl-20 flex overflow-x-scroll no-scrollbar items-center md:justify-center w-[100vw] max-w-screen-lg`}
        >
          {cities.map((city, index) => (
            <button
              key={city.id}
              className={`p-2 m-2 rounded-full border-2  px-9 transition-all duration-200 ease-in-out
                ${
                  selectedIndex === index
                    ? "bg-secondary text-primary rounded-br-none"
                    : "border-gray-300 hover:bg-gray-100"
                }
                ${index === cities.length - 1 ? "mr-20" : ""}`}
              onClick={() => setSelectedIndex(index)}
            >
              {city.name}
            </button>
          ))}
        </div>

        {/* content */}
        <main className="mt-10">
          {packageData == null ? (
            <div>
              <LoadingScreen />
            </div>
          ) : (
            <div className="relative w-[100vw] h-auto  max-w-screen-lg">
              <div className="">
                {/* main image */}
                <Slide
                  cssClass={`bg-black user-select-none max-h-[30rem] aspect-video`}
                  autoplay={true}
                  infinite
                  pauseOnHover={false}
                  easing="ease-in"
                  transitionDuration={500}
                  arrows={false}
                >
                  {packageData.gallery.map((imageLink, index) => (
                    <LazyLoadImage
                      key={index}
                      src={imageLink}
                      alt="gallery_image"
                      className="object-cover aspect-video max-h-[30rem] w-full"
                    />
                  ))}
                </Slide>
              </div>

              {/* floating container*/}
              <div
                ref={floatingContainer}
                className="absolute bg-[#f9fdff] top-[80%] flex flex-col md:flex-row gap-5 md:gap-14 p-5 md:p-14 mx-5 shadow-md"
              >
                {/* container 1 */}
                <div className="flex flex-col md:w-[85%]">
                  {/* destination name */}
                  <h2 className="text-2xl font-bold">
                    {packageData.destination}
                  </h2>
                  <p className="line-clamp-6 mt-3">{packageData.description}</p>
                </div>

                {/* container 2 */}
                <div className="flex flex-wrap gap-3 ">
                  {servicesList.map((service, index) => {
                    return (
                      <div
                        key={index}
                        className={`flex flex-wrap h-fit w-fit gap-4 items-center bg-white p-3 shadow-md shadow-gray-200
                            border rounded-md whitespace-nowrap`}
                        style={{
                          color: service.color,
                        }}
                      >
                        <div> {service.icon}</div>
                        <h3>{service.title}</h3>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </main>

        {/* this is the container which has the height of the floating container height
        this insures that the below content doesnt go below the floating container */}
        <div
          style={{
            height: floatingContentHeight,
          }}
        ></div>
      </div>
    </div>
  );
}
