import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  collection,
  doc,
  getDocs,
  limit,
  query,
  startAfter,
} from "firebase/firestore";
import { db } from "../../../firebase_config/firebase";

// Define the async thunk

// Define the maximum number of documents to fetch per request

export const getAllJobsThunk = createAsyncThunk(
  "careerSlice/fetchJobs",
  async (_, thunkAPI) => {
    try {
      const querySnapshot = await getDocs(collection(db, "jobs"));
      const jobs = querySnapshot.docs.map((doc) => doc.data());

      return jobs;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const initialState = {
  allJobs: [],

  loading: false,
  error: null,
};

// Helper function to filter out duplicate jobs by id
const filterUniqueJobs = (existingJobs, newJobs) => {
  const existingJobIds = new Set(existingJobs.map((job) => job.id));
  return newJobs.filter((job) => !existingJobIds.has(job.id));
};

const careerSlice = createSlice({
  name: "careerSlice",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getAllJobsThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllJobsThunk.fulfilled, (state, action) => {
        state.loading = false;

        state.allJobs = action.payload;
        state.lastDoc = action.payload.lastDoc;
      })
      .addCase(getAllJobsThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const {} = careerSlice.actions;

export default careerSlice.reducer;
