import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../state/slices/auth/authSlice";
import singlePackageReducer from "../state/slices/single_package/single_package_slice";
import homeScreenReducer from "../state/slices/home_page/home_page_slice";
import allPackagesReducer from "../state/slices/all_packages/all_packages_slice";
import adminReducer from "../state/slices/admin/admin_slice";
import careerReducer from "../state/slices/career/careerSlice";

// Configure the store
const store = configureStore({
  reducer: {
    authSlice: authReducer,
    singlePackage: singlePackageReducer,
    homePageSlice: homeScreenReducer,
    allPackagesSlice: allPackagesReducer,
    adminSlice: adminReducer,
    careerSlice: careerReducer,
  },
  devTools: process.env.NODE_ENV !== "production", // Enable DevTools only in development
});

export default store;
