import React from "react";
import CustomIcons from "../../../constants/icons";
import { FaHeartbeat } from "react-icons/fa";
import { ImCancelCircle } from "react-icons/im";
import { FaMapLocationDot } from "react-icons/fa6";
import { GiDuration } from "react-icons/gi";
import { GiConfirmed } from "react-icons/gi";
import { IoLanguage } from "react-icons/io5";

const advantages = [
  [
    {
      name: "Free Cancellation",
      description: "Cancel up to 24 hours in advance to receive a full refund",
      icon: <ImCancelCircle />,
    },
    {
      name: "Health Precautions",
      description: "Special health and safety measures apply. Learn more",
      icon: <FaHeartbeat />,
    },
    {
      name: "Mobile Ticketing",
      description: "Use your phone or print your voucher",
      icon: <FaMapLocationDot />,
    },
    {
      name: "Duration",
      description: "Check availability to see starting times.",
      icon: <GiDuration />,
    },

    {
      name: "Instant Confirmation",
      description: "Don’t wait for the confirmation!",
      icon: <GiConfirmed />,
    },
    {
      name: "Live Tour Guide",
      description: "English",
      icon: <IoLanguage />,
    },
  ],
];

export default function Advantages() {
  return (
    <div className="md:mt-24 lg:mt-0">
      {advantages.map((advantage, index) => (
        <div key={index}>
          <ul className="grid grid-cols-1 md:grid-cols-2 p-5 py-7 gap-7 gap-x-16 bg-teal-50 my-9 md:my-5">
            {advantage.map((item, index) => (
              <li key={index} className="flex gap-5 items-center">
                {item.icon && (
                  <div className="bg-teal-600 text-white p-3 rounded-full">
                    <span className="text-2xl">{item.icon}</span>
                  </div>
                )}
                <div>
                  <h4 className="font-semibold">{item.name}</h4>
                  <p>{item.description}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
}
