import { useSelector } from "react-redux";

import { FaStar } from "react-icons/fa6";
import CustomIcons from "../../../constants/icons";

export default function PackageHeader() {
  const packageData = useSelector((state) => state.singlePackage.packageData);
  return (
    <div className="m-5 mt-9 ml-1.5 flex flex-col gap-1">
      {/* package name */}
      <h1 className="text-3xl font-bold">{packageData.package_name}</h1>

      <div className="flex flex-col md:flex-row items-start md:items-center gap-4 mt-5 px-1 md:px-0">
        {/* location */}
        <div className="flex flex-wrap uppercase gap-2  items-center md:border-r-2 md:pr-5">
          <div className="p-1 shadow-sm rounded-full border bg-gray-50">
            {CustomIcons.location("h-5 w-5")}
          </div>
          <p className="text-lg">{packageData.country}</p>
        </div>

        {/* reviews */}
        <div className="flex items-center gap-2">
          <div className="flex">
            {Array.from({ length: 5 }).map((_, index) => (
              <FaStar key={index} size={17} color="#ffa432" />
            ))}
          </div>
          <p>( {Math.floor(Math.random() * (1000 - 300 + 1)) + 300} reviews)</p>
        </div>
      </div>
    </div>
  );
}
