import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  query,
} from "firebase/firestore";
import { db } from "../../../firebase_config/firebase";
import Utils from "../../../utils/utils";

export const getTopDomesticToursThunk = createAsyncThunk(
  "homePageSlice/domesticTours",
  async () => {
    try {
      const q = query(collection(db, "packages"));
      const querySnapshot = await getDocs(q);
      const data = querySnapshot.docs.map((doc) => doc.data());

      return data;
    } catch (error) {
      return error.message;
    }
  }
);
export const getExploreCitypackageData = createAsyncThunk(
  "homePageSlice/getExploreCitypackageData",
  async (package_id, { rejectWithValue }) => {
    try {
      const docRef = doc(db, "packages", package_id);

      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        return docSnap.data();
      } else {
        return rejectWithValue(404);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const getGalleyImagesThunk = createAsyncThunk(
  "homePageSlice/galleryImages",
  async () => {
    const imageLinks = [];

    try {
      const q = query(collection(db, "packages"), limit(6));
      const querySnapshot = await getDocs(q);
      const data = querySnapshot.docs.map((doc) => doc.data());
      data.forEach((item) => {
        console.log(item);
        item.gallery.slice(0, 2).map((link) => imageLinks.push(link));
      });

      return imageLinks;
    } catch (error) {
      return error.message;
    }
  }
);

const initState = {
  topDomesticTours: [],
  galleryImageLinks: [],
  exploreCitypackageData: null,
};

const homePageSlice = createSlice({
  name: "homePageSlice",
  initialState: initState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(getTopDomesticToursThunk.fulfilled, (state, action) => {
      state.topDomesticTours = action.payload;
    });

    builder.addCase(getGalleyImagesThunk.fulfilled, (state, action) => {
      state.galleryImageLinks = action.payload;
    });

    builder.addCase(getExploreCitypackageData.fulfilled, (state, action) => {
      state.exploreCitypackageData = action.payload;
    });
  },
});

export const {} = homePageSlice.actions;
export default homePageSlice.reducer;
