import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedin,
  FaYoutube,
} from "react-icons/fa6";
import { IoLogoWhatsapp } from "react-icons/io";

export default function Footer() {
  const location = useLocation();
  const isAdmin = location.pathname === "/admin";
  let navigate = useNavigate();

  const scrollTo = (id) => {
    const targetElement = document.getElementById(id);
    if (targetElement) {
      targetElement.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const handleLinkClick = (event, path, id = null) => {
    event.preventDefault();
    navigate(path);
    if (id) {
      setTimeout(() => {
        scrollTo(id);
      }, 100); // Adjust the delay if necessary
    }
  };

  return (
    <div
      className={` ${
        isAdmin ? "hidden" : "block"
      } max-w-screen-2xl bg-[#f9f9f9]`}
    >
      <div className="lg:px-20 mx-auto">
        <footer className="footer text-base-content p-10">
          <nav>
            <h6 className="footer-title">Services</h6>
            <Link to="/packages" className="link link-hover">
              All Packages
            </Link>
            <button
              className="link link-hover"
              onClick={(e) =>
                handleLinkClick(e, "/", "top_international_packages")
              }
            >
              International Packages
            </button>
            <Link
              className="link link-hover"
              onClick={(e) => handleLinkClick(e, "/", "top_domestic_packages")}
            >
              Domestic Packages
            </Link>
          </nav>
          <nav>
            <h6 className="footer-title">Company</h6>
            <Link to="/contact-us" className="link link-hover">
              Contact us
            </Link>
            <Link to="/about-us" className="link link-hover">
              About us
            </Link>

            <Link to="/career" className="link link-hover">
              Career
            </Link>
          </nav>

          <nav>
            <h6 className="footer-title">Legal</h6>
            <Link to="/terms-and-condition" className="link link-hover">
              Terms of use
            </Link>
            <Link to="privacy-policy" className="link link-hover">
              Privacy policy
            </Link>
          </nav>
          <nav>
            <h6 className="footer-title">Help And Support</h6>
            <Link to="/about-us" className="link link-hover">
              About us
            </Link>
            <Link to="/contact-us" className="link link-hover">
              Contact
            </Link>
          </nav>
        </footer>
        <footer className="footer  text-base-content border-base-300 border-t border-b px-10 py-4">
          <aside className="grid-flow-col items-center">
            <img className="h-10" src="/images/logo/logo_colored.png" alt="" />
          </aside>
          <nav className="md:place-self-center md:justify-self-end">
            <div className="grid grid-flow-col gap-4 text-xl">
              <a
                href="https://www.facebook.com/share/EKurYiQu2XN4QgQ2/?mibextid=qi2Omg"
                target="_blank"
                rel="noreferrer"
                className="border-gray-200 shadow-sm border p-2 rounded-full hover:border-gray-400"
              >
                <FaFacebookF />
              </a>
              <a
                href="https://www.instagram.com/albaca_international?igsh=Mmd6cmpidWdnMmdh"
                target="_blank"
                rel="noreferrer"
                className="border-gray-200 shadow-sm border p-2 rounded-full hover:border-gray-400"
              >
                <FaInstagram />
              </a>
              <a
                href="http://www.linkedin.com/in/sajath-gani-9a6070189"
                target="_blank"
                rel="noreferrer"
                className="border-gray-200 shadow-sm border p-2 rounded-full hover:border-gray-400"
              >
                <FaLinkedin />
              </a>
              <a
                href="https://wa.me/6369299802?text="
                target="_blank"
                rel="noreferrer"
                className="border-gray-200 shadow-sm border p-2 rounded-full hover:border-gray-400"
              >
                <IoLogoWhatsapp />
              </a>
              <a
                href="https://youtube.com/@albacainternational?si=Q_W6u91ugRvSJGU8"
                target="_blank"
                rel="noreferrer"
                className="border-gray-200 shadow-sm border p-2 rounded-full hover:border-gray-400"
              >
                <FaYoutube />
              </a>
            </div>
          </nav>
        </footer>
        <footer className="footer footer-center  text-base-content p-4">
          <aside>
            <p>
              Copyright © {new Date().getFullYear()} - All right reserved by
              Albaca International Pvt Ltd.
            </p>
          </aside>
        </footer>
      </div>
    </div>
  );
}
