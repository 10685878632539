import { Slide } from "react-slideshow-image";
import CustomIcons from "../../../../constants/icons";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function CustomerReviews() {
  const testimonials = [
    {
      title: "Excellent Service",
      name: "John Doe",
      image:
        "https://images.unsplash.com/photo-1566753323558-f4e0952af115?q=80&w=1921&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      review:
        "I couldn't be happier with the service I received. The team went above and beyond to meet my needs and ensure everything was perfect. Highly recommended!",
    },
    {
      title: "Great Experience",
      name: "Jane Smith",
      image:
        "https://images.unsplash.com/photo-1485178575877-1a13bf489dfe?q=80&w=2001&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      review:
        "My experience with this company has been fantastic from start to finish. The staff are friendly, knowledgeable, and always willing to help. Will definitely be returning!",
    },
    {
      title: "Fantastic Support",
      name: "Rimsha Sharma",
      image:
        "https://images.unsplash.com/photo-1667132970685-a2109a3ed00d?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      review:
        "The support I received was second to none. Any issues or questions I had were addressed promptly and efficiently. I'm impressed with the level of service!",
    },
    {
      title: "Amazing Results",
      name: "Ankur Singh",
      image:
        "https://images.unsplash.com/photo-1607346256330-dee7af15f7c5?q=80&w=1812&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      review:
        "I am blown away by the results achieved by this company. They exceeded my expectations in every way possible. Couldn't be happier with the outcome!",
    },
    {
      title: "Superb Quality",
      name: "Radhika Sharma",
      image:
        "https://images.unsplash.com/photo-1622782262245-bfb660f4ff93?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      review:
        "The quality of the products and services provided by this company is outstanding. I have never been disappointed with anything I've received. Keep up the great work!",
    },
  ];

  return (
    <div className="pt-20 max-w-screen-md mx-auto">
      <div className="">
        <div className="text-center text-3xl font-bold">
          <h2 className="text-white">Our Customer Testimonials</h2>
        </div>
        <div className="mt-10">
          <Slide
            transitionDuration={500}
            infinite={true}
            children={testimonials.map((testimonial, index) => (
              <div
                key={index}
                className="flex flex-col items-center justify-center bg-white text-gray-800 p-9 md:px-20 md:py-14 transform-gpu "
              >
                <div className="flex flex-col items-center justify-center">
                  <LazyLoadImage
                    src={testimonial.image}
                    alt="customer review"
                    className="rounded-full h-20 w-20 object-cover shrink-0"
                  />

                  <h3 className=" text-md font-semibold mt-10 text-gray-600">
                    {testimonial.name}
                  </h3>
                  <h3 className=" text-lg font-semibold mt-2 bg-teal-300 px-5 py-1">
                    {testimonial.title}
                  </h3>
                </div>
                <p className="text-center mt-7">{testimonial.review}</p>
              </div>
            ))}
            prevArrow={CustomIcons.arrowLeft(
              "text-black absolute top-1/2 left-0  bg-gray-200 ml-2 shadow-md rounded-full p-1.5 hover:scale-110 transition-all duration-200 ease-in-out backface-visibility-hidden",
              37
            )}
            nextArrow={CustomIcons.arrowRight(
              "text-black absolute top-1/2 right-0  bg-gray-200 mr-2 shaddw-md rounded-full p-1.5 hover:scale-110 transition-all duration-200 ease-in-out backface-visibility-hidden",
              37
            )}
          ></Slide>
        </div>
      </div>
    </div>
  );
}
