import { useEffect, useRef, useState } from "react";
import { getTopDomesticToursThunk } from "../../../../state/slices/home_page/home_page_slice";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineCalendar, AiOutlineCar, AiOutlineTeam } from "react-icons/ai";
import { FaStar } from "react-icons/fa";
import { Slide } from "react-slideshow-image";
import CustomIcons from "../../../../constants/icons";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function BrowseTours({ isDomestic = true }) {
  const features = [
    {
      title: "Flexible Booking",
      icon: <AiOutlineCalendar />,
    },
    {
      title: "Transport Facility",
      icon: <AiOutlineCar />,
    },
    {
      title: "Family Plan",
      icon: <AiOutlineTeam />,
    },
  ];

  const allTours = useSelector((state) => state.homePageSlice.topDomesticTours);

  const [filteredTours, setFilteredTours] = useState([]);

  useEffect(() => {
    if (isDomestic) {
      setFilteredTours(
        allTours.filter((tour) => tour.country.trim().toLowerCase() === "india")
      );
    } else {
      setFilteredTours(
        allTours.filter((tour) => tour.country.trim().toLowerCase() !== "india")
      );
    }
  }, [allTours, isDomestic]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTopDomesticToursThunk());
  }, [dispatch]);

  const reviews = () => {
    //  reviews
    return (
      <div className="flex items-center justify-end">
        <div className="text-sm flex items-end flex-col gap-1">
          <div className="flex">
            {Array.from({ length: 5 }).map((_, index) => (
              <FaStar key={index} size={13} color="#ffa432" />
            ))}
          </div>
          <p>{Math.floor(Math.random() * (1000 - 300 + 1)) + 300} reviews</p>
        </div>
      </div>
    );
  };

  const container = useRef(null);
  const handleScroll = (right = true) => {
    if (right) {
      container.current.scrollBy({ left: 300, behavior: "smooth" }); // Scrolls 300px to the right
    } else {
      container.current.scrollBy({ left: -300, behavior: "smooth" }); // Scrolls 300px to the left
    }
  };

  // return
  return (
    <div
      className="max-w-screen-xl mx-auto py-10 md:pt-14"
      id={isDomestic ? "top_domestic_packages" : "top_international_packages"}
    >
      <div className="p-5">
        <h2 className="text-xl tracking-widest font-semibold text-secondary">
          EXPLORE
        </h2>
        <h2 className="text-2xl font-bold">
          Top {isDomestic ? "Domestic" : "International"} Tours
        </h2>
      </div>
      {/* browse domestic tours */}

      <div>
        <div className="relative">
          <div className="opacity-0 md:opacity-100">
            <div>
              <div className="absolute left-0 bg-white opacity-70 h-[100%] w-10 z-10"></div>
              <div className="absolute right-0 bg-white opacity-70 h-[100%] w-10 z-10 "></div>
            </div>
            {/* arrow keys */}

            {/* right icon */}
            <div
              className="absolute right-0 top-1/2 bg-white p-2 border shadow-md rounded-full z-10 cursor-pointer hover:scale-105 transition-all duration-300 ease-in-out"
              onClick={() => handleScroll()}
            >
              {CustomIcons.arrowRight()}
            </div>
            {/* left icon */}
            <div
              className="absolute left-0 top-1/2 bg-white p-2 border shadow-md rounded-full z-10 cursor-pointer hover:scale-105 transition-all duration-300 ease-in-out"
              onClick={() => handleScroll(false)}
            >
              {CustomIcons.arrowLeft()}
            </div>
          </div>
          <div
            ref={container}
            className="relative flex  overflow-x-auto no-scrollbar gap-5 pl-20 p-5 pr-20"
          >
            {filteredTours.map((tour) => (
              <div className="w-60 shadow-md p-3 rounded-2xl border  flex-shrink-0  cursor-pointer group">
                <Link to={`/packages/${tour.package_id}`} key={tour.package_id}>
                  <div className="relative overflow-hidden rounded-2xl ">
                    <LazyLoadImage
                      src={tour.gallery[0]}
                      alt="tour_image"
                      className="w-full h-52 object-cover  group-hover:scale-105 transition-transform duration-500 ease-in-out"
                    />
                    <div className="absolute bottom-0 right-0 bg-white px-5 p-1 pt-2 rounded-tl-2xl">
                      {reviews()}
                    </div>
                  </div>

                  <div className="mt-3 px-1">
                    {/* title */}
                    <h3 className="text-lg font-semibold">
                      {tour.package_name}
                    </h3>

                    {/* features */}
                    <div clas className="flex flex-col gap-3 pt-3">
                      {features.map((feature) => (
                        <div
                          key={feature.title}
                          className="flex gap-3 items-center text-sm"
                        >
                          {feature.icon}
                          <p>{feature.title}</p>
                        </div>
                      ))}
                    </div>

                    <div className="h-0.5 mt-5 w-full bg-gray-200"></div>

                    {/* stars */}
                    <div className="flex justify-center">
                      {/* price */}
                      <div className="mt-2">
                        <p className="text-xs font-bold text-[#7bbcb0] uppercase">
                          starting for just
                        </p>
                        <p className="text-lg font-semibold">
                          ₹ {tour.price}{" "}
                          <span className="text-sm">/person</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
